import { useAppDispatch, useAppSelector } from "@/modules/common";

import { resetActiveFilters, selectProducts, setActiveFilters } from "..";
import { useProducts } from ".";

export function useTags() {
  const dispatch = useAppDispatch();
  const products = useAppSelector(selectProducts);
  const { refetchProducts } = useProducts();

  function onFilter(category: string, value: string): void {
    dispatch(setActiveFilters({ category, value }));
    refetchProducts();
  }

  function isActive(category: string, value: string): boolean {
    return products.activeFilters.some(
      x => x.value === value && x.category === category
    );
  }

  function resetFilter(): void {
    if (products.activeFilters.length === 0) return;
    dispatch(resetActiveFilters());
    refetchProducts();
  }

  return {
    onFilter,
    isActive,
    resetFilter,
    tags: products.tags,
  };
}
