import {
  AspectRatio,
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight, FaYoutube } from "react-icons/fa";

import { Spinner } from "./Spinner";

type Props = {
  images?: string[];
  video?: string;
};

export const Carousel = ({ images, video }: Props) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState<string>();
  const [items, setItems] = useState<string[]>([]);
  const carouselItemsRef = useRef<HTMLDivElement[] | null[]>([]);

  const youtubeParser = (url: string) => {
    const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[1].length == 11
      ? `https://www.youtube.com/embed/${match[1]}`
      : "";
  };

  useEffect(() => {
    if (!images && !video) return;
    if (!video && images) {
      setItems(images);
      return;
    }
    if (images && video) setItems([...images, youtubeParser(video)]);
  }, [images, video]);

  useEffect(() => {
    if (items && items[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(
        0,
        items.length
      );

      setSelectedItemIndex(0);
      setSelectedItem(items[0]);
    }
  }, [items]);

  const handleSelectedItemChange = (newIdx: number) => {
    if (items && items.length > 0) {
      setSelectedItem(items[newIdx]);
      setSelectedItemIndex(newIdx);
      if (carouselItemsRef?.current[newIdx]) {
        carouselItemsRef?.current[newIdx]?.scrollIntoView({
          inline: "center",
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  };

  const handleRightClick = () => {
    if (items && items.length > 0) {
      let newIdx = selectedItemIndex + 1;
      if (newIdx >= items.length) {
        newIdx = 0;
      }
      handleSelectedItemChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (items && items.length > 0) {
      let newIdx = selectedItemIndex - 1;
      if (newIdx < 0) {
        newIdx = items.length - 1;
      }
      handleSelectedItemChange(newIdx);
    }
  };

  if (!items)
    return (
      <Center>
        <Spinner />
      </Center>
    );

  return (
    <Flex flexDir="column" w="full" gap={3}>
      <Box bg="bsGray.100" w="full" borderRadius={5}>
        {!selectedItem?.includes("youtube") && (
          <AspectRatio
            maxW={{ base: "300px", md: "400px", lg: "500px" }}
            ratio={1}
            mx="auto"
          >
            <Image
              src={selectedItem}
              w="full"
              objectPosition="center"
              blendMode="multiply"
              borderRadius={5}
              p={14}
              style={{ objectFit: "contain" }}
            />
          </AspectRatio>
        )}
        {selectedItem?.includes("youtube") && (
          <AspectRatio
            minW="full"
            maxH={{ base: "300px", md: "400px", lg: "500px" }}
            ratio={1}
            borderRadius={5}
            data-cy="video"
            mx="auto"
          >
            <iframe
              title="YouTube video player"
              src={selectedItem}
              style={{ borderRadius: "5px" }}
            >
              <Text>De browser ondersteunt deze video niet</Text>
            </iframe>
          </AspectRatio>
        )}
      </Box>
      {items.length > 1 && (
        <Flex position="relative" alignItems="center">
          <IconButton
            bg="bsWhite"
            h="full"
            color="bsBlack"
            minW="24px"
            justifyContent="start"
            icon={<Icon as={FaChevronLeft} />}
            _hover={{ bg: "transparent" }}
            aria-label={""}
            onClick={handleLeftClick}
          />
          <Flex
            overflowX="auto"
            w="content"
            gap={3}
            css={{
              "&::-webkit-scrollbar": {
                height: "0",
              },
            }}
          >
            {items &&
              items.map((item, idx) => (
                <Box
                  key={idx}
                  minW="100px"
                  h="100px"
                  bg="bsGray.100"
                  borderColor="bsAccent"
                  borderRadius={5}
                >
                  {!item.includes("youtube") && (
                    <Image
                      key={idx}
                      ref={el => (carouselItemsRef.current[idx] = el)}
                      w="full"
                      h="full"
                      objectFit="contain"
                      p={4}
                      src={item}
                      opacity={selectedItemIndex === idx ? 1 : 0.2}
                      blendMode="multiply"
                      _hover={{ cursor: "pointer" }}
                      onClick={() => handleSelectedItemChange(idx)}
                    />
                  )}
                  {item.includes("youtube") && (
                    <Flex
                      data-testid="youtube"
                      ref={el => (carouselItemsRef.current[idx] = el)}
                      w="full"
                      h="full"
                      justifyContent="center"
                      alignItems="center"
                      opacity={selectedItemIndex === idx ? 1 : 0.2}
                      _hover={{ cursor: "pointer" }}
                      onClick={() => handleSelectedItemChange(idx)}
                    >
                      <FaYoutube color="#ff0000" size="50px" />
                    </Flex>
                  )}
                </Box>
              ))}
          </Flex>

          <IconButton
            bg="bsWhite"
            h="full"
            color="bsBlack"
            justifyContent="end"
            minW="24px"
            icon={<Icon as={FaChevronRight} />}
            _hover={{ bg: "transparent" }}
            aria-label={""}
            onClick={handleRightClick}
          />
        </Flex>
      )}
    </Flex>
  );
};
