import { Flex, Text, Textarea, VStack } from "@chakra-ui/react";
import React from "react";

type Props = {
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
};

export function Remark({ setRemarks: setRemark }: Props) {
  return (
    <Flex justifyContent="center" w="full" bg="white" flexDir="column">
      <VStack alignItems="flex-start" fontSize="sm" mt={6}>
        <Text fontSize="md" fontWeight="bold">
          Opmerkingen
        </Text>
        <Textarea
          fontSize="sm"
          onChange={e => setRemark(e.currentTarget.value)}
        />
      </VStack>
    </Flex>
  );
}
