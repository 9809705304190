import { createAsyncThunk } from "@reduxjs/toolkit";

import { BasketApiImpl } from "@/modules/basket";

export const getSaturdayDeliveryUsabilityThunk = createAsyncThunk(
  "orders/saturday-delivery-product",
  () => {
    return BasketApiImpl.getInstance().getSaturdayDeliveryProductIfApplicable();
  }
);
