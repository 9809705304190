import {
  Divider,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import { FiUser } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";

import { selectUser } from "@/modules/auth/data/selectUser";
import { auth } from "@/modules/auth/firebase";
import { useAppSelector } from "@/modules/common";

export function UserMenu() {
  const user = useAppSelector(selectUser);
  function onLogout(): void {
    signOut(auth);
  }
  return (
    <Menu>
      <MenuButton
        data-cy="userMenuButton"
        as={IconButton}
        bg="none"
        _hover={{ bg: "none" }}
        disabled={!user}
      >
        <Icon color="#5e5753" as={FiUser} w={7} h={7} mt={1} />
      </MenuButton>
      <MenuList
        bg="bsWhite"
        border="1px solid"
        borderColor="bsGray.200"
        borderRadius={5}
        p={0}
        data-testid="menuDropdown"
      >
        <MenuItem
          borderTopRadius="5"
          fontSize="xs"
          data-cy="logoutButton"
          fontWeight="bold"
          p={5}
          _hover={{
            cursor: "default",
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
        >
          <Text>Welkom {user?.email}</Text>
        </MenuItem>
        <Divider borderColor="bsGray.200" />
        <Link as={RouterLink} to="/account" _hover={{ textDecor: "none" }}>
          <MenuItem
            fontSize="xs"
            h={14}
            px={5}
            _hover={{
              bg: "bsAccent",
              color: "bsWhite",
            }}
          >
            Account
          </MenuItem>
        </Link>
        <Link as={RouterLink} to="/bestellingen" _hover={{ textDecor: "none" }}>
          <MenuItem
            fontSize="xs"
            h={14}
            px={5}
            _hover={{
              bg: "bsAccent",
              color: "bsWhite",
            }}
          >
            Bestellingen
          </MenuItem>
        </Link>
        <MenuItem
          borderBottomRadius="5"
          fontSize="xs"
          data-cy="logoutButton"
          p={5}
          _hover={{
            bg: "bsAccent",
            color: "bsWhite",
          }}
          onClick={onLogout}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
