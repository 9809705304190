import { Center, Text } from "@chakra-ui/react";

type Props = {
  message: string;
};

export function EmptyListMessage({ message }: Props) {
  return (
    <Center>
      <Text
        mt="5"
        color="gray.500"
        fontSize="sm"
        px={10}
        data-cy="emptyListMessage"
      >
        {message}
      </Text>
    </Center>
  );
}
