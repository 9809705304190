import { Link, Text, VStack } from "@chakra-ui/react";

export function EmptySearchMessage() {
  return (
    <VStack w="full" alignItems="center" mt="5" color="gray.500" fontSize="sm">
      <Text>
        Er zijn geen producten gevonden die voldoen aan je zoekcriteria.
      </Text>
      <Text>
        Wil jij je merkassortiment uitbreiden? Klik dan{" "}
        <Link
          href={process.env.VITE_BD_BRAND_EXPANSION_REQUEST_URL}
          fontWeight="bold"
          color="bsBlack"
        >
          hier!
        </Link>
      </Text>
    </VStack>
  );
}
