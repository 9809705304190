import { CustomerNotFoundProblemDetails } from "./CustomersApi";

export function errorMapper(details: CustomerNotFoundProblemDetails): string {
  switch (details.type) {
    case "CustomerNotFoundException":
      return "De gegevens kunnen niet worden verwerkt";
    default:
      return `Er ging iets fout. Error Code: ${details.type}`;
  }
}
