import { Flex, Text, VStack } from "@chakra-ui/react";
import { useEffect } from "react";

import { AddSaturdayDelivery } from "@/modules/basket";
import { useAppSelector } from "@/modules/common";
import {
  NewCustomerDto,
  selectCustomer,
  useCustomer,
} from "@/modules/customers";

export function Shipping() {
  const { getCustomer } = useCustomer();
  const { data } = useAppSelector(selectCustomer);
  const customer = data as NewCustomerDto;

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <Flex justifyContent="center" w="full" bg="white" flexDir="column">
      <VStack alignItems="flex-start" fontSize="sm">
        <Text fontSize="md" fontWeight="bold">
          Factuuradres
        </Text>
        <Text>{customer?.name}</Text>
        <Text>{customer?.address.street}</Text>
        <Text>
          {customer?.address.postalCode} {customer?.address.city}
        </Text>
        <Text my={4}>{customer?.address.country}</Text>
      </VStack>

      <AddSaturdayDelivery />
    </Flex>
  );
}
