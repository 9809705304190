import { Layout } from "@/modules/layout";
import { useAppSelector } from "@/modules/common";
import { selectIsAuthenticated } from "@/modules/auth/data/selectIsAuthenticated";
import { Navigate, useLocation } from "react-router-dom";

type Props = {
  children: JSX.Element;
};

export function ProtectiveRoute({ children }: Props) {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { pathname } = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={{ pathname: "/inloggen", search: `redirect=${pathname}` }}
      />
    );
  }

  return <Layout>{children}</Layout>;
}
