import { ProtectedRoute } from "../common";
import { ModuleRouter } from "../common/types";
import { CheckoutPage } from "./CheckoutPage";

export * from "./CheckoutPage";

export const paymentPageRouterConfig: ModuleRouter = [
  {
    path: "/checkout",
    element: (
      <ProtectedRoute>
        <CheckoutPage />
      </ProtectedRoute>
    ),
  },
];
