import { Flex, FormControl, Select, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useAppDispatch, useAppSelector } from "@/modules/common";
import { getPaymentProviderOptionsThunk } from "@/modules/customers/api/GetPaymentProviderOptionsThunk";
import { selectPaymentProviderOptions } from "@/modules/customers/api/SelectPaymentProviderOptions";
import { PaymentDto } from "@/modules/orders/api";

type Props = {
  setPaymentMethod: React.Dispatch<
    React.SetStateAction<PaymentDto | undefined>
  >;
  paymentMethod: string | undefined;
};

export function Payment({ paymentMethod, setPaymentMethod }: Props) {
  const dispatch = useAppDispatch();
  const paymentProviderOpts = useAppSelector(selectPaymentProviderOptions);

  const schema = yup.object({
    paymentMethod: yup.string().required(),
  });

  const {
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    dispatch(getPaymentProviderOptionsThunk());
  }, []);

  return (
    <Flex justifyContent="center" w="full" bg="white" flexDir="column">
      <FormControl>
        <VStack alignItems="flex-start" fontSize="sm">
          <Text fontSize="md" fontWeight="bold">
            Betaalmethode
          </Text>
          <Select
            data-cy="paymentSelection"
            placeholder="Selecteer een betaalmethode"
            fontSize="sm"
            letterSpacing={1}
            borderRadius={5}
            _hover={{ cursor: "pointer" }}
            _focusVisible={{
              outlineColor:
                errors.paymentMethod && !paymentMethod ? "#E53E3E" : "bsBlack",
              outlineOffset: -1,
            }}
            {...register("paymentMethod", { required: true })}
            onChange={e =>
              setPaymentMethod(e.currentTarget.value as PaymentDto)
            }
          >
            {paymentProviderOpts.map(opt => (
              <option key={opt.value} value={opt.value}>
                {opt.name}
              </option>
            ))}
          </Select>
          {errors.paymentMethod && !paymentMethod && (
            <Text color="#E53E3E" fontSize="xs" mt={1} ml={2}>
              Selecteer een betaalmethode om te betalen
            </Text>
          )}
        </VStack>
      </FormControl>
    </Flex>
  );
}
