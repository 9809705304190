import { HStack, Icon, Text } from "@chakra-ui/react";
import { MdOutlineClear } from "react-icons/md";

type Props = {
  label: string;
  isClosable?: boolean;
  onClose?: () => void;
  isLoading?: boolean;
  chipBgColor?: string;
  chipColor?: string;
};

export const Chip = ({
  label,
  isClosable,
  onClose,
  isLoading,
  chipBgColor,
  chipColor,
}: Props) => {
  return (
    <HStack
      key={label}
      pl={2}
      pr={1}
      py={1}
      mx={0}
      color={chipColor ? chipColor : "white"}
      bg={chipBgColor ? chipBgColor : "bsAccent"}
      opacity={isLoading ? 0.3 : 1}
      pointerEvents={isLoading ? "none" : "inherit"}
      borderRadius={5}
    >
      <Text fontSize="xs" letterSpacing={0.5}>
        {label}
      </Text>
      {isClosable && (
        <Icon
          as={MdOutlineClear}
          _hover={{ cursor: "pointer" }}
          onClick={onClose}
        />
      )}
    </HStack>
  );
};
