import { BasketContainsDuplicateCouponsProblemDetails } from "@/modules/basket";
import { HttpProblemDetails } from "@/modules/common";
import {
  CouponAlreadyUsedProblemDetails,
  NotFoundExceptionProblemDetails,
} from "@/modules/coupon/api/CouponApi";
import { CustomerNotFoundProblemDetails } from "@/modules/customers";
import {
  BasketRequiresAtleastOneProductProblemDetails,
  FailedToCreateTransactionUrlProblemDetails,
  FailedToSaveOrderProblemDetails,
  InvalidPaymentProviderProblemDetails,
  SaturdayDeliveryIsNotAllowedProblemDetails,
} from "@/modules/orders/api/OrdersApi";
import {
  FailedToGetProductProblemDetails,
  FailedToGetProductsFromBigQueryProblemDetails,
  FailedToGetProductsFromCacheProblemDetails,
} from "@/modules/products";

export function errorMapper<TDetails extends HttpProblemDetails>(
  details: TDetails
): string {
  const errors: Record<string, (details: any) => string | null> = {
    FailedToCreateTransactionUrlException: (
      details: FailedToCreateTransactionUrlProblemDetails
    ) => "Betalen is op dit moment helaas niet mogelijk",
    InvalidPaymentProviderException: (
      details: InvalidPaymentProviderProblemDetails
    ) => "Kies een andere betalingsmethode",
    BasketRequiresAtleastOneProductException: (
      details: BasketRequiresAtleastOneProductProblemDetails
    ) => "Controleer de winkelwagen",
    SaturdayDeliveryIsNotAllowedException: (
      details: SaturdayDeliveryIsNotAllowedProblemDetails
    ) => "Leveren op een zaterdag is helaas niet mogelijk",
    BasketContainsDuplicateCouponsException: (
      details: BasketContainsDuplicateCouponsProblemDetails
    ) => "Het winkelmandje bevat twee dezelfde kortingscodes",
    CouponAlreadyUsedException: (details: CouponAlreadyUsedProblemDetails) =>
      "Deze kortingscode is al gebruikt",
    CustomerNotFoundException: (details: CustomerNotFoundProblemDetails) =>
      "De gegevens kunnen niet worden verwerkt",
    NotFoundException: (details: NotFoundExceptionProblemDetails) =>
      "Er kon iets niet gevonden worden",
    CouponNonExistentException: (details: NotFoundExceptionProblemDetails) =>
      "Deze kortingscode is niet geldig",
    FailedToGetProductException: (details: FailedToGetProductProblemDetails) =>
      "Het product kan niet worden opgehaald",
    FailedToGetProductsFromCacheException: (
      details: FailedToGetProductsFromCacheProblemDetails
    ) => "De producten kunnen niet worden opgehaald",
    FailedToGetProductsFromBigQueryException: (
      details: FailedToGetProductsFromBigQueryProblemDetails
    ) => "De producten kunnen niet worden opgehaald",
    FailedToSaveOrderException: (details: FailedToSaveOrderProblemDetails) =>
      "De bestelling kan niet worden geplaatst",
  };

  const error = errors[details.type];

  if (!error) {
    return `Er is nog geen vertaling voor error code '${details.type}'`;
  }

  return error(details)!;
}
