import { ProtectedRoute } from "../common";
import { ModuleRouter } from "../common/types";
import { MaintenancePage } from "./MaintenancePage";

export * from "./MaintenancePage";

export const maintenancePageRouterConfig: ModuleRouter = [
  {
    path: "/maintenance",
    element: (
      <ProtectedRoute>
        <MaintenancePage />
      </ProtectedRoute>
    ),
  },
];
