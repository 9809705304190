import {
  Container,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Link as ReactLink, Navigate, useLocation } from "react-router-dom";

import { SkeletonCustomer } from "@/modules/customers/components/SkeletonCustomer";

import BdContact from "../../assets/bd-contact.jpg";
import { ReturnToLastPageButton, useAppSelector } from "../common";
import {
  CustomerBalance,
  CustomerForm,
  NewCustomerDto,
  selectCustomer,
} from ".";
import { selectIsAuthenticated } from "@/modules/auth/data/selectIsAuthenticated";

export function CustomerPage() {
  const { data, status } = useAppSelector(selectCustomer);

  const isLoading = status === "loading";
  const customer = data as NewCustomerDto;

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { pathname } = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={{ pathname: "/inloggen", search: `redirect=${pathname}` }}
      />
    );
  }

  return (
    <Container maxW="1400px" mx="auto" h="full" pb={8}>
      <ReturnToLastPageButton />

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacingX={16} pb={6}>
        <Flex w="full" h="full">
          <Image
            src={BdContact}
            alt="Beauty Shop Contact"
            h={{ base: "250px", md: "450px", lg: "750px" }}
            w="full"
            borderRadius={5}
            mb={5}
            objectFit="cover"
          />
        </Flex>
        <Flex flexDir="column" w="half">
          <Flex justifyContent="space-between" w="full" flexDir="column">
            <Heading
              as="h1"
              size={"2xl"}
              fontWeight="bold"
              lineHeight={1.2}
              mb={8}
            >
              <Text lineHeight={1.2}>
                Jouw <i>Gegevens</i>
              </Text>
            </Heading>
          </Flex>
          {isLoading && <SkeletonCustomer />}
          {!isLoading && data && customer && (
            <>
              <Flex
                mb={5}
                alignItems="end"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <CustomerBalance {...data} />
                <Link
                  as={ReactLink}
                  to="/password"
                  type="submit"
                  w="content"
                  ml={2}
                  mt={3}
                  letterSpacing={1}
                  fontSize="sm"
                >
                  Wachtwoord wijzigen
                </Link>
              </Flex>
              <CustomerForm {...customer} />
            </>
          )}
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
