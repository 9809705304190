import { HttpProblemDetails } from "@/modules/common";
import { axios } from "@/modules/common/Axios";
import { HttpResponseWithProblems } from "@/modules/common/HttpResponseWithProblems";
import { isHttpProblemDetail } from "@/modules/notifications/data/IsHttpProblemDetail";

export type FailedToCreateTransactionUrlProblemDetails = HttpProblemDetails<{
  data: {
    additionalInfo: string;
  };
}>;

export type InvalidPaymentProviderProblemDetails = HttpProblemDetails<{
  data: {
    additionalInfo: string;
  };
}>;

export type BasketRequiresAtleastOneProductProblemDetails = HttpProblemDetails<{
  data: {
    additionalInfo: string;
  };
}>;

export type SaturdayDeliveryIsNotAllowedProblemDetails = HttpProblemDetails<{
  data: {
    additionalInfo: string;
  };
}>;

export type FailedToSaveOrderProblemDetails = HttpProblemDetails<{
  data: {
    additionalInfo: string;
  };
}>;
type TransactionUrlDto = {
  transactionUrl?: string;
  status?: number;
};

type TransactionUrlWithStatusDto = TransactionUrlDto & { status: number };

type CreateOrderResponse =
  | TransactionUrlWithStatusDto
  | FailedToCreateTransactionUrlProblemDetails
  | FailedToSaveOrderProblemDetails;

type SaturdayDeliveryUsabilityResponse = { data: { usable: boolean } };

export class OrdersApi {
  public static async createOrder(data: {
    paymentMethod?: string;
    remarks?: string;
  }): Promise<HttpResponseWithProblems<CreateOrderResponse>> {
    const res = await axios.post("/api/v1/orders", data);

    if (isHttpProblemDetail(res.data)) {
      return res.data;
    }

    if (res.data.data) {
      return {
        data: {
          transactionUrl: res.data.data.transactionUrl,
          status: res.status,
        },
      };
    }

    return {
      data: {
        transactionUrl: undefined,
        status: res.status,
      },
    };
  }

  public static async getSaturdayDeliveryUsability(): Promise<SaturdayDeliveryUsabilityResponse> {
    return axios
      .get("/api/v1/orders/saturday-delivery-usability")
      .then(({ data }) => data);
  }

  public static async getOrderHistory(): Promise<any> {
    return axios.get("/api/v1/orders/history").then(({ data }) => data.data);
  }
}
