import { Divider, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";

import fallbackImage from "@/assets/bd-placeholder.svg";
import { BasketProduct } from "@/modules/basket";
import { useMoney } from "@/modules/common";

type Props = BasketProduct;

export function OrderProduct({
  name,
  thumbnailUrl,
  price,
  quantity,
  priceVAT,
}: Props) {
  const totalItemPriceWithoutVat = quantity * Number(price);
  const formattedTotalItemPriceWithoutVat = useMoney(totalItemPriceWithoutVat);

  const totalItemPriceWithVat = quantity * Number(priceVAT);
  const formattedTotalItemPriceWithVat = useMoney(totalItemPriceWithVat);

  return (
    <>
      <Flex
        w="100%"
        bg="bsWhite"
        border="1px solid"
        borderColor="bsGray.200"
        justifyContent="space-between"
        alignItems="center"
        borderRadius={5}
        flexDir="row"
        gap={0}
        py={3}
      >
        <Flex alignItems="start">
          <Image
            mx={4}
            boxSize={{ base: 75 }}
            fit="contain"
            src={thumbnailUrl}
            fallbackSrc={fallbackImage}
            alt={name}
            height={{ sm: "80px" }}
            textAlign="center"
          />
        </Flex>
        <Flex flex={1} flexDir="column" w="full" p={2}>
          <HStack justify="space-between" align="start">
            <Text fontWeight="bold" fontSize={{ base: "xs", md: "sm" }}>
              {name}
            </Text>
          </HStack>
        </Flex>
        <Flex flex={1}>
          <HStack align="flex-start" p={2}>
            <Text>{quantity} x</Text>
          </HStack>
        </Flex>
        <Flex>
          <VStack align="flex-end" p={2}>
            <Text
              fontSize="sm"
              fontWeight="bold"
              letterSpacing={1}
              pt={1}
              minW="fit-content"
            >
              {formattedTotalItemPriceWithoutVat}
            </Text>
            <Text
              fontSize="sm"
              letterSpacing={1}
              pt={1}
              color="gray.400"
              minW="fit-content"
            >
              incl. btw {formattedTotalItemPriceWithVat}
            </Text>
          </VStack>
        </Flex>
      </Flex>
      <Divider />
    </>
  );
}
