import { Button } from "@chakra-ui/react";
import { ReactElement } from "react";

type Props = {
  leftIcon?: ReactElement;
  isLoading?: boolean;
  action?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  buttonText: string;
  order?: { md: string; sm: string };
  alignSelf?: string;
  width?: { md: string; sm: string } | string;
  marginBottom?: number;
  marginTop?: number;
  disabled?: boolean;
  dataTestid?: string;
};

export function CTAButton({
  isLoading,
  action,
  buttonText,
  leftIcon,
  type,
  order,
  alignSelf,
  width,
  marginBottom,
  marginTop,
  disabled,
  dataTestid,
}: Props) {
  return (
    <Button
      data-testid={dataTestid}
      leftIcon={leftIcon}
      disabled={disabled}
      {...(order && { order: order })}
      {...(alignSelf && { alignSelf: alignSelf })}
      {...(marginBottom && { mb: marginBottom })}
      {...(marginTop && { mt: marginTop })}
      bg="bsBlack"
      color="bsWhite"
      fontWeight="regular"
      fontSize="sm"
      borderRadius={5}
      px={5}
      w={width ? width : "content"}
      isLoading={isLoading}
      _hover={{
        bg: "bsBlack",
        _disabled: {
          opacity: 0.4,
        },
      }}
      _active={{
        bg: "bsBlack",
      }}
      type={type ? type : "button"}
      onClick={action}
    >
      {buttonText}
    </Button>
  );
}
