import "@fontsource/poppins";
import "./main.css";

import { version } from "../package.json";

import { ChakraProvider } from "@chakra-ui/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";

import App from "./App";
import { persistor, store } from "./modules/common";
import { theme } from "./theme";

const container = document.getElementById("root");
const root = createRoot(container as HTMLDivElement);

if (process.env.VITE_ENV === "production") {
  Sentry.init({
    dsn: "https://baf58845e794456283ebc155d91fd3e8@o1258509.ingest.sentry.io/4504933830295552",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.5,
    release: `beautyshop@${version}`,
  });
}

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);
