import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
  },
  colors: {
    bsGreen: {
      300: "#7B9682",
    },
    bsBrown: {
      300: "#978984",
    },
    bsPrimary: "",
    bsSecondary: "",
    bsAccent: "#5e5753",
    bsBlack: "black",
    bsGray: {
      100: "#efefef",
      200: "#E2E8F0",
    },
    bsWhite: "white",
    bsBackground: "white",
  },
  styles: {
    global: {
      "#root, html, body": {
        bg: "bsBackground",
        height: "100%",
        color: "bsBlack",
      },
    },
  },
  shadows: {
    outline: "0 0 0 3px rgb(1, 186, 191)",
  },
  breakpoints: {
    sm: "320px",
    ms: "600px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1400px",
    "3xl": "2100px",
    "4xl": "2800px",
  },
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: "bsBlack",
        },
      },
    },
    Checkbox: {
      baseStyle: {
        icon: {
          color: "bsWhite",
        },
        control: {
          bg: "bsWhite",
          border: "1px solid",
          borderColor: "gray.300",
          borderWidth: "1px",
          _checked: {
            bg: "bsBlack",
            borderColor: "bsBlack",
            _hover: {
              bg: "bsBlack",
            },
          },
          _disabled: {
            bg: "white",
          },
        },
        label: {
          color: "bsBlack",
          fontSize: "10px",
          _checked: {
            color: "bsBlack",
          },
          _disabled: {
            color: "bsBlack",
          },
        },
      },
    },
  },
});
