import {
  Box,
  Container,
  Flex,
  SimpleGrid,
  Skeleton,
  Stack,
  VStack,
} from "@chakra-ui/react";

import { ReturnToLastPageButton } from "@/modules/common";

export function DetailSkeleton() {
  return (
    <Container maxW="1400px" pb={8}>
      <ReturnToLastPageButton />
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacingX={16}
        letterSpacing={1}
        pb={6}
      >
        <Flex flexDir="column" w="full" gap={3}>
          <Skeleton
            w="full"
            borderRadius={5}
            p={14}
            h={{ base: "300px", md: "400px", lg: "500px" }}
          />
        </Flex>
        <Flex
          flexDir="column"
          gap={{ base: 4, lg: 8 }}
          mt={{ base: 8, lg: 0 }}
          w="full"
        >
          <Box data-cy="name">
            <Skeleton w="100%" h={7} mb={2} />
            <Skeleton w="100%" h={4} />
          </Box>
          <Box data-cy="name">
            <Skeleton w="100%" h={24} />
          </Box>
          <Stack>
            <Skeleton w="200px" h={4} />
            <Skeleton w="200px" h={7} />
          </Stack>
          <Box>
            <Skeleton w="50px" h={4} borderRadius={5} />
            <SimpleGrid
              columns={{ base: 1, sm: 2, ms: 3, md: 4, lg: 3, xl: 4 }}
              mt={2}
              spacing={2}
            >
              <Skeleton w="100%" h={10} />
              <Skeleton w="100%" h={10} />
              <Skeleton w="100%" h={10} />
              <Skeleton w="100%" h={10} />
              <Skeleton w="100%" h={10} />
              <Skeleton w="100%" h={10} />
              <Skeleton w="100%" h={10} />
              <Skeleton w="100%" h={10} />
            </SimpleGrid>
          </Box>

          <Flex
            w="full"
            justifyContent="space-between"
            alignItems="center"
            my={5}
          >
            <VStack justifyContent="start" alignItems="start" lineHeight={1}>
              <Skeleton w="50px" h={4} borderRadius={5} />
              <Skeleton w="120px" h={8} borderRadius={5} />
            </VStack>
            <VStack justifyContent="end" alignItems="end" lineHeight={1}>
              <Skeleton w="60px" h={8} borderRadius={5} />
              <Skeleton w="100px" h={4} borderRadius={5} />
            </VStack>
          </Flex>
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
