import { Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { CTAButton, OutlineButton } from "@/modules/common";
import { CustomPasswordField } from "@/modules/common/components/CustomPasswordField";
import { useNotifications } from "@/modules/notifications/hooks/UseNotifications";

import { auth } from "../auth/firebase";

const schema = yup
  .object({
    currentPassword: yup
      .string()
      .required("Voer huidig wachtwoord in")
      .min(6, "Het wachtwoord dient minimaal 6 karakters lang te zijn"),
    newPassword: yup
      .string()
      .required("Voer een nieuw wachtwoord in")
      .min(6, "Het wachtwoord dient minimaal 6 karakters lang te zijn"),
    repeatPassword: yup
      .string()
      .required("Herhaal het nieuwe wachtwoord")
      .min(6, "Het wachtwoord dient minimaal 6 karakters lang te zijn")
      .oneOf(
        [yup.ref("newPassword"), null],
        "Nieuwe wachtwoorden komen niet overeen"
      ),
  })
  .required();

export function ChangePasswordPage() {
  const [user] = useAuthState(auth);
  const [validCurrentPassword, setValidCurrentPassword] =
    useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const notifications = useNotifications();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      repeatPassword: "",
    },
  });
  const reauthenticate = async (currentPassword: string) => {
    if (!user) return;
    const credentials = EmailAuthProvider.credential(
      // eslint-disable-next-line
      user.email!,
      currentPassword
    );
    try {
      await reauthenticateWithCredential(user, credentials);
      setValidCurrentPassword(true);
      return true;
    } catch (e) {
      setValidCurrentPassword(false);
      console.error(e);
      return false;
    }
  };

  const onSubmit = handleSubmit(async data => {
    setLoading(true);
    const response = await reauthenticate(data.currentPassword);

    if (!user || !response) {
      setLoading(false);
      return;
    }

    try {
      await updatePassword(user, data.newPassword);
      navigate("/account");
      notifications.success(
        "Wachtwoord gewijzigd",
        "Het wachtwoord is gewijzigd"
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Flex
      h="full"
      w="full"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      maxW="1400px"
      mx="auto"
      pb={8}
    >
      <Flex
        flexDir="column"
        w="half"
        justifyContent="center"
        mx={{ base: 5, md: 20 }}
        mt={6}
        gap={2}
      >
        <Heading
          as="h1"
          size={{ sm: "lg", md: "2xl" }}
          fontWeight="bold"
          lineHeight={1.2}
          mb={{ base: 4, md: 8 }}
        >
          <Text lineHeight={1.2}>
            Wachtwoord <i>WIJZIGEN</i>
          </Text>
        </Heading>

        <Text fontSize="sm">
          Vul je alle drie de velden in om het wachtwoord te wijzigen
        </Text>
        <form onSubmit={onSubmit}>
          <Flex
            justifyContent="space-between"
            w="full"
            flexDir="column"
            mt={5}
            gap={4}
          >
            <CustomPasswordField
              placeholder="Huidig wachtwoord"
              isInvalid={
                Boolean(errors.currentPassword) || !validCurrentPassword
              }
              register={register("currentPassword")}
              errorMessage={errors.currentPassword?.message}
              onBlur={() => setValidCurrentPassword(true)}
            />
            <CustomPasswordField
              placeholder="Nieuw wachtwoord"
              isInvalid={Boolean(errors.newPassword)}
              register={register("newPassword")}
              errorMessage={errors.newPassword?.message}
            />
            <CustomPasswordField
              placeholder="Nieuw wachtwoord herhalen"
              isInvalid={Boolean(errors.repeatPassword)}
              register={register("repeatPassword")}
              errorMessage={errors.repeatPassword?.message}
            />
            <HStack justifyContent="space-between" mt={5}>
              <OutlineButton
                buttonText="Annuleren"
                paddingY={3}
                action={() => navigate("/account")}
              />
              <CTAButton
                buttonText="Wachtwoord opslaan"
                type="submit"
                data-cy="savePassword"
                isLoading={loading}
              />
            </HStack>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
}
