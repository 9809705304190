import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: any;
};

export function Modal({ isOpen, onClose, children }: Props) {
  return (
    <ChakraModal isCentered isOpen={isOpen} size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={5} mx={5}>
        <ModalCloseButton />
        <ModalBody px={10} py={10}>
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
}
