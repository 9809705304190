import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";
import { FallbackProps } from "react-error-boundary";

export const ErrorFallback: React.ComponentType<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  function handleOnBack() {
    resetErrorBoundary();
  }

  return (
    <Container minH="100%" maxW="container.md" pb={8}>
      <Box as="header" mb={8}>
        <Heading color="red.500" pt={12}>
          Het lijkt erop dat er iets is fout gegaan
        </Heading>
        <Text mt={2}>
          Gebeurt dit vaker? Neem contact op via <b>support@zzapps.nl</b>
        </Text>
      </Box>
      <Accordion allowToggle>
        <AccordionItem bgColor="gray.50">
          <Heading as="h2">
            <AccordionButton
              borderBottom="1px solid"
              borderColor="gray.200"
              py={3}
            >
              <Box flex="1" textAlign="left">
                {error.message}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} lineHeight="1.9">
            {error.stack}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Box as="footer" display="flex" justifyContent="flex-end" w="100%">
        <Button
          fontWeight="normal"
          mt={4}
          bg="red.500"
          color="white"
          _hover={{
            opacity: 0.8,
            bg: "red.500",

            "&:active": {
              bg: "red.500",
            },
          }}
          onClick={handleOnBack}
        >
          Opnieuw proberen
        </Button>
      </Box>
    </Container>
  );
};
