import { useAppDispatch } from "@/modules/common";

import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
} from "../data/createNotification";
import { addNotification } from "../data/NotificationsSlice";

export function useNotifications() {
  const dispatch = useAppDispatch();

  function success(title: string, description: string): void {
    dispatch(addNotification(createSuccessNotification(title, description)));
  }

  function info(title: string, description: string): void {
    dispatch(addNotification(createInfoNotification(title, description)));
  }

  function error(title: string, description: string): void {
    dispatch(addNotification(createErrorNotification(title, description)));
  }

  return {
    success,
    info,
    error,
  };
}
