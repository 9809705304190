import { Flex, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { selectCoupons } from "@/modules/basket/data/selectors";
import {
  Chip,
  CustomInputField,
  TextButton,
  useAppDispatch,
  useAppSelector,
} from "@/modules/common";
import { CTAButton } from "@/modules/common/components/CTAButton";
import { Modal } from "@/modules/common/components/Modal";
import { isHttpProblemDetail } from "@/modules/notifications/data/IsHttpProblemDetail";

import { updateCouponsThunk } from "../data/thunks/UpdateCouponsThunk";

export function Coupons() {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const coupons = useAppSelector(selectCoupons);
  const [loading, setLoading] = useState(false);
  const [loadingCoupons, setLoadingCoupons] = useState(false);

  const schema = yup.object({
    coupon: yup
      .string()
      .required("Kortingscode dient minimaal 1 karakter te bevatten")
      .min(1),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      coupon: "",
    },
  });

  const handleCoupons = handleSubmit(async data => {
    if (coupons.some(x => x === data.coupon)) {
      setError("coupon", {
        message: "Probeer een andere kortingscode",
      });
      return;
    }

    setLoading(true);
    const res = await dispatch(updateCouponsThunk([...coupons, data.coupon]));
    setLoading(false);

    if (isHttpProblemDetail(res.payload)) {
      return;
    }

    onClose();
    reset();
  });

  async function onDelete(code: string) {
    setLoadingCoupons(true);
    await dispatch(updateCouponsThunk(coupons.filter(x => x !== code)));
    setLoadingCoupons(false);
  }

  return (
    <>
      <Flex flexDirection="column" alignItems="start">
        <TextButton
          text={"+ Kortingscode toevoegen"}
          variant={"primary"}
          onClick={onOpen}
        />
        <Flex wrap="wrap" gap={2} mt={3}>
          {coupons.map(coupon => (
            <Chip
              key={coupon}
              isClosable
              isLoading={loadingCoupons}
              label={coupon}
              onClose={() => onDelete(coupon)}
            />
          ))}
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Text fontSize="md" fontWeight="bold">
          Kortingscode toevoegen
        </Text>
        <form onSubmit={handleCoupons}>
          <VStack justifyContent="end" alignItems="end" mt={5}>
            <CustomInputField
              hasAutoFocus
              label="Kortingscode"
              register={register("coupon")}
              isInvalid={Boolean(errors.coupon)}
              errorMessage={errors.coupon?.message}
              showLabel={false}
            />
            <CTAButton
              data-cy="addCouponCode"
              buttonText="Toevoegen"
              isLoading={loading}
              type="submit"
              data-name="addCouponCode"
            />
          </VStack>
        </form>
      </Modal>
    </>
  );
}
