import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Text,
} from "@chakra-ui/react";

import { Chip, useAppSelector } from "@/modules/common";
import { selectProducts } from "@/modules/products/api";
import { useTags } from "@/modules/products/hooks";

export const ResetFilters = () => {
  const { onFilter, resetFilter } = useTags();
  const { activeFilters } = useAppSelector(selectProducts);

  return (
    <Accordion
      allowMultiple
      border="none"
      w="250px"
      index={[0]}
      display={{ base: "none", lg: "inline" }}
    >
      <AccordionItem
        border="none"
        w="full"
        p={5}
        py={0}
        px={0}
        borderBottom="1px solid"
        borderColor="gray.300"
      >
        <AccordionButton
          w="full"
          px={0}
          py={1}
          _hover={{ bg: "transparent", cursor: "default" }}
        >
          <Box flex="1" textAlign="left" fontSize="sm" fontWeight="bold">
            <HStack justifyContent="space-between">
              <Text>
                Actieve Filters{" "}
                {activeFilters.length > 0 ? `(${activeFilters.length})` : ""}
              </Text>
              {activeFilters.length > 0 && (
                <Text
                  fontWeight="normal"
                  _hover={{ cursor: "pointer", textDecor: "underline" }}
                  onClick={() => resetFilter()}
                >
                  Reset Filters
                </Text>
              )}
            </HStack>
          </Box>
        </AccordionButton>
        <AccordionPanel px={0} py={5}>
          <Flex flexDir="row" flexWrap="wrap" gap={1}>
            {activeFilters &&
              activeFilters.map(e => (
                <Chip
                  key={e.value}
                  isClosable
                  chipColor="bsBlack"
                  chipBgColor="bsGray.200"
                  label={e.value}
                  onClose={() => onFilter(e.category, e.value)}
                />
              ))}
            {activeFilters.length < 1 && (
              <Text ml={2} py={0.5} fontSize="sm" color="gray.400">
                Geen filters actief
              </Text>
            )}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
