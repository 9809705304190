import { ProtectedRoute } from "../common";
import { ModuleRouter } from "../common/types";
import { ProductDetailsPage } from "./details";
import { SeriesProductDetailsPage } from "./details/SeriesProductDetailsPage";
import { ProductsPage } from "./ProductsPage";

export * from "./api";
export * from "./components";
export * from "./details";
export * from "./hooks";
export * from "./ProductsPage";

export const productsPageRouterConfig: ModuleRouter = [
  {
    path: "/producten",
    element: (
      <ProtectedRoute>
        <ProductsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/producten/:slug",
    element: (
      <ProtectedRoute>
        <ProductDetailsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/producten/series/:name/:slug",
    element: (
      <ProtectedRoute>
        <SeriesProductDetailsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <ProductsPage />
      </ProtectedRoute>
    ),
  },
];
