import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "@/modules/common";

import { FetchAllProductsArgs, ProductsApi } from ".";

export const fetchProducts = createAsyncThunk(
  "products/all",
  (args: Omit<FetchAllProductsArgs, "tags">, thunk) => {
    const state = thunk.getState() as RootState;
    const query = state.products.activeFilters
      .map(x => x.category + ":" + x.value)
      .join(",");

    const search = state.products.search ?? "";

    const searchQuery = `&search=${encodeURIComponent(search)}`;
    const tags = query.length > 0 ? "&tags=" + encodeURIComponent(query) : "";
    return ProductsApi.all(
      { ...args, tags, search: searchQuery },
      thunk.signal
    );
  }
);

export const loadMoreProducts = createAsyncThunk(
  "products/load-more",
  (args: Omit<FetchAllProductsArgs, "tags">, thunk) => {
    const state = thunk.getState() as RootState;
    const query = state.products.activeFilters
      .map(x => x.category + ":" + x.value)
      .join(",");

    const search = state.products.search ?? "";

    const searchQuery = `&search=${encodeURIComponent(search)}`;
    const tags = query.length > 0 ? "&tags=" + encodeURIComponent(query) : "";
    return ProductsApi.all(
      { ...args, tags, search: searchQuery },
      thunk.signal
    );
  }
);
