import {
  combineReducers,
  configureStore,
  PreloadedState,
} from "@reduxjs/toolkit";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { httpProblemDetailsMiddleware } from "@/modules/notifications/data/middleware/HttpProblemDetailsMiddleware";
import { rejectedPromiseMiddleware } from "@/modules/notifications/data/middleware/RejectedPromiseMiddleware";
import { notificationsReducer } from "@/modules/notifications/data/NotificationsSlice";

import { authReducer } from "../auth/data/AuthSlice";
import { basketSlice } from "../basket/data";
import { customersSlice } from "../customers/api/CustomersSlice";
import { historySlice } from "../history/api";
import { ordersSlice } from "../orders/api";
import { productsSlice } from "../products/api";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["customer", "history"],
};

const rootReducer = combineReducers({
  products: productsSlice.reducer,
  basket: basketSlice.reducer,
  customers: customersSlice.reducer,
  orders: ordersSlice.reducer,
  auth: authReducer,
  notifications: notificationsReducer,
  history: historySlice.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(httpProblemDetailsMiddleware)
        .concat(rejectedPromiseMiddleware),
  });
};

export const store = setupStore();
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
