import { Button } from "@chakra-ui/react";

type Props = {
  action?: () => void;
  dataCy?: string;
  buttonText: string;
  order?: { md: string; sm: string };
  alignSelf?: string;
  width?: { md: string; sm: string } | string;
  marginBottom?: number;
  paddingY?: number;
};

export function OutlineButton({
  action,
  dataCy,
  buttonText,
  order,
  alignSelf,
  width,
  marginBottom,
  paddingY,
}: Props) {
  return (
    <Button
      data-cy={dataCy}
      {...(order && { order: order })}
      {...(alignSelf && { alignSelf: alignSelf })}
      {...(marginBottom && { mb: marginBottom })}
      fontWeight="regular"
      fontSize="sm"
      borderRadius={5}
      borderColor="bsBlack"
      color="bsBlack"
      py={paddingY}
      px={5}
      variant="outline"
      w={width ? width : "content"}
      _hover={{
        bg: "bsWhite",
        color: "bsBlack",
      }}
      type="button"
      onClick={action}
    >
      {buttonText}
    </Button>
  );
}
