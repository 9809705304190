import { createAsyncThunk } from "@reduxjs/toolkit";

import { OrdersApi } from "./OrdersApi";

export const createOrder = createAsyncThunk(
  "orders",
  (data: { paymentMethod?: string; remarks?: string }) => {
    return OrdersApi.createOrder(data);
  }
);
