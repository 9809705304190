export type QuantityHandlerType = "incr" | "decr" | "manual";

export type QuantityHandlerProps = {
  type: QuantityHandlerType;
  value: number;
  manualValue?: number;
  minQuantity: number;
  maxQuantity: number;
  stepSize: number;
};

export class ProductQuantityHandler {
  public handle(props: QuantityHandlerProps): number {
    let newValue = 0;

    if (props.type === "incr") {
      newValue = this.onIncrement(props);
    }

    if (props.type === "decr") {
      newValue = this.onDecrement(props);
    }

    if (props.type === "manual") {
      newValue = props.manualValue!;
      if (newValue < props.minQuantity && newValue !== 0)
        newValue = props.minQuantity;
      if (newValue >= props.maxQuantity) newValue = props.maxQuantity;
    }

    const mod = newValue % props.stepSize;

    if (mod !== 0) {
      if (mod / props.stepSize > 0.5) {
        newValue = newValue - mod + props.stepSize;
      } else {
        newValue = newValue - mod;
      }
    }

    return newValue;
  }

  private onIncrement(props: QuantityHandlerProps): number {
    let newValue = props.value + props.stepSize;
    if (newValue === 0) newValue = props.minQuantity;
    if (newValue >= props.maxQuantity) newValue = props.maxQuantity;
    return newValue;
  }

  private onDecrement(props: QuantityHandlerProps): number {
    let newValue = props.value - props.stepSize;
    if (newValue < props.minQuantity) newValue = 0;
    return newValue;
  }
}
