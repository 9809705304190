import {
  Container,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import Maintenance from "../../assets/maintenance.png";

export function MaintenancePage() {
  return (
    <Container
      maxW="100%"
      bg="white"
      h="100%"
      position="fixed"
      zIndex={3}
      top="0"
    >
      <Flex
        bg="white"
        maxW="1200px"
        mx="auto"
        px={{ base: 8, md: 16 }}
        flexDir={{ base: "column-reverse", md: "row" }}
        w="full"
        mt={32}
        gap={12}
      >
        <VStack alignItems="flex-start" justifyContent="center">
          <Heading as="h2" fontWeight="bold">
            Onderhoud
          </Heading>
          <Text>
            Onze excuses. Momenteel zijn wij druk bezig met het onderhouden en
            updaten van de webshop. Voor vragen kun je ons telefonisch bereiken
            op
            <b> 085 489 6333</b> of mailen naar <b>Info@beautydistrict.nl</b>
          </Text>
        </VStack>
        <Flex>
          <Image
            src={Maintenance}
            alt="Beauty District Logo"
            w={{ base: "350px", md: "650px" }}
            objectFit="contain"
          />
        </Flex>
      </Flex>
    </Container>
  );
}
