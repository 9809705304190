import { HttpProblemDetails } from "@/modules/common";
import { axios } from "@/modules/common/Axios";

import { ProductDetailDto, ProductDto, TagDto } from ".";
import { QuantityInfoDto } from "./QuantityInfoDto";

export type FailedToGetProductProblemDetails = HttpProblemDetails<{
  additionalInfo: string;
}>;

export type FailedToGetProductsFromBigQueryProblemDetails = HttpProblemDetails<{
  additionalInfo: string;
}>;

export type FailedToGetProductsFromCacheProblemDetails = HttpProblemDetails<{
  additionalInfo: string;
}>;

export type FetchAllProductsArgs = {
  page: number;
  limit: number;
  tags?: string;
  search?: string;
};

type GetReorderInfoResponse = {
  data: {
    data: QuantityInfoDto;
  }[];
};

type GetAllProductsResponse =
  | { data: ProductDto[]; tags: TagDto[]; hasMore: boolean; totalHits: number }
  | FailedToGetProductsFromBigQueryProblemDetails
  | FailedToGetProductsFromCacheProblemDetails;

type GetProductDetailResponse =
  | { data: ProductDetailDto }
  | FailedToGetProductProblemDetails;

type GetProductSeriesDetailResponse =
  | { data: { products: ProductDetailDto[] } }
  | FailedToGetProductProblemDetails;

export class ProductsApi {
  public static async all(
    { page, limit, tags, search }: FetchAllProductsArgs,
    signal: AbortSignal
  ): Promise<GetAllProductsResponse> {
    return axios
      .get(
        `/api/v1/products/search?page=${page}&limit=${limit}${tags}${search}`,
        {
          signal,
        }
      )
      .then(({ data }) => data);
  }

  public static async detail(slug: string): Promise<GetProductDetailResponse> {
    return axios.get(`/api/v1/products/${slug}`).then(({ data }) => data);
  }

  public static async seriesDetail(
    name: string
  ): Promise<GetProductSeriesDetailResponse> {
    const uri = encodeURIComponent(name);
    return axios.get(`/api/v1/products/series/${uri}`).then(({ data }) => data);
  }

  public static async reoderInfo(
    productIds: string[]
  ): Promise<GetReorderInfoResponse> {
    return axios.post(`api/v1/products/quantityinfo`, { productIds });
  }
}
