import { Heading } from "@chakra-ui/react";

type Props = {
  text: string;
  fontSize: string;
};

export function Title({ text, fontSize = "1.2rem" }: Props) {
  return (
    <Heading as="h2" fontSize={fontSize}>
      {text}
    </Heading>
  );
}
