import {
  Badge,
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { updateBasketProductsThunk } from "@/modules/basket/data/thunks/UpdateBasketProductsThunk";
import {
  Carousel,
  ReturnToLastPageButton,
  useAppDispatch,
  useMoney,
} from "@/modules/common";
import { ChangeProductAmount } from "@/modules/common/components/ChangeProductAmount";
import { ColorChip } from "@/modules/common/components/ColorChip";
import { isHttpProblemDetail } from "@/modules/notifications/data/IsHttpProblemDetail";
import { useNotifications } from "@/modules/notifications/hooks/UseNotifications";
import { ProductDetailDto, ProductDto, ProductsApi } from "@/modules/products";
import { DetailSkeleton } from "@/modules/products/details/components";

export function SeriesProductDetailsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notification = useNotifications();
  const { slug, name } = useParams();

  const [products, setProducts] = useState<ProductDetailDto[]>([]);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const requestRef = useRef<() => void>();

  const activeProduct = products?.find(prod => prod.id === activeProductId);
  const productsHasLength = products.length > 0;

  const formattedPriceWithVat = useMoney(activeProduct?.priceVAT || 0);
  const formattedPrice = useMoney(Number(activeProduct?.price || 0));

  function updateBasket(product: ProductDto, quantity: number) {
    if (requestRef.current) {
      requestRef.current();
    }

    const req = dispatch(updateBasketProductsThunk({ ...product, quantity }));

    requestRef.current = () => req.abort();
  }

  useEffect(() => {
    setIsLoading(true);
    if (!name || !slug) return;
    ProductsApi.seriesDetail(name)
      .then(data => {
        if (isHttpProblemDetail(data)) {
          notification.error("Er ging iets mis", "Kan geen producten vinden");
          return;
        }
        setProducts(data.data.products);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [name, slug]);

  const handleActiveProduct = () => {
    if (!activeProductId && productsHasLength && slug) {
      const prod = products.find(prod => prod.slug === slug);

      if (!prod) return setActiveProductId(products[0].id);

      setActiveProductId(prod.id);
    }
  };

  const handleProductColorNavigation = () => {
    if (activeProductId && productsHasLength && slug && name) {
      const prod = products.find(prod => prod.id === activeProductId);

      if (!prod) return navigate("/");

      navigate(`/producten/series/${name}/${prod.slug}`, { replace: true });
    }
  };

  const sortedProducts = useMemo(() => {
    return [...products]?.sort((a, b) =>
      a.colorName!.localeCompare(b.colorName!, undefined, {
        numeric: true,
      })
    );
  }, [products]);

  useEffect(() => {
    handleActiveProduct();
    handleProductColorNavigation();
  }, [activeProductId, products, slug, name]);

  if (!isLoading && products.length === 0) {
    return <Navigate replace to="/" />;
  }

  if (isLoading || !activeProduct) {
    return <DetailSkeleton />;
  }

  const filteredCarouselImages = activeProduct.additionalImageUrls.filter(
    image => image !== activeProduct.colorImage
  );

  return (
    <Container maxW="1400px" pb={8}>
      <ReturnToLastPageButton />
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacingX={16}
        letterSpacing={1}
        pb={6}
      >
        <Carousel
          images={[activeProduct.imageUrl!, ...filteredCarouselImages]}
          video={activeProduct.videoUrl}
        />
        <Flex
          flexDir="column"
          gap={{ base: 4, lg: 8 }}
          mb={activeProduct.additionalImageUrls.length === 0 ? 4 : 0}
          mt={{ base: 8, lg: 0 }}
          w="full"
        >
          <Box data-cy="name" lineHeight={1.8}>
            {activeProduct.remark && (
              <HStack
                mb={{ base: 2, md: 5 }}
                fontWeight="bold"
                fontSize="sm"
                letterSpacing={1}
              >
                <Badge
                  px={2}
                  py={1}
                  color="bsBlack"
                  bg="bsGray.200"
                  borderRadius="5"
                >
                  {activeProduct.remark}
                </Badge>
              </HStack>
            )}
            <Text fontSize="2xl" fontWeight="bold" lineHeight={1.3}>
              {activeProduct.name}
            </Text>
            <Flex
              gap={2}
              alignItems="center"
              flexDir="row"
              lineHeight={1.2}
              mt={1}
            >
              <Flex>
                <Text fontSize="sm" color="gray.400" pr={1}>
                  Art. Nr:
                </Text>
                <Text fontSize="sm" color="gray.400">
                  {activeProduct.articleNumber}
                </Text>
              </Flex>
              {activeProduct.ean && (
                <>
                  <Divider orientation="vertical" h="3" />
                  <Flex>
                    <Text fontSize="sm" color="gray.400" pr={1}>
                      EAN:
                    </Text>
                    <Text fontSize="sm" color="gray.400">
                      {activeProduct.ean}
                    </Text>
                  </Flex>
                </>
              )}
            </Flex>

            {activeProduct.description && (
              <Text
                justifyContent="start"
                fontSize="sm"
                alignItems="start"
                mt={5}
              >
                {activeProduct.description}
              </Text>
            )}
          </Box>
          {activeProduct.resaleInfo && (
            <>
              <Box lineHeight={1.5}>
                <Text textTransform="uppercase" color="gray.400" fontSize="xs">
                  Consumentenprijs
                </Text>
                <Text fontWeight="bold">{activeProduct.resaleInfo}</Text>
              </Box>
            </>
          )}
          <Box>
            <Text textTransform="uppercase" color="gray.400" fontSize="xs">
              Kleuren
            </Text>
            <SimpleGrid
              columns={{ base: 1, sm: 2, ms: 3, md: 4, lg: 3, xl: 4 }}
              mt={2}
              spacing={2}
            >
              {sortedProducts.map(prod => {
                return (
                  <ColorChip
                    key={prod.id}
                    isActive={prod.id === activeProductId}
                    image={prod.colorImage}
                    label={prod.colorName}
                    onClick={() => setActiveProductId(prod.id)}
                  />
                );
              })}
            </SimpleGrid>
          </Box>

          <Flex
            w="full"
            justifyContent="space-between"
            alignItems="center"
            my={5}
          >
            <VStack justifyContent="start" alignItems="start" lineHeight={1}>
              <ChangeProductAmount
                {...activeProduct}
                updateBasket={item => updateBasket(activeProduct, item.amount)}
              />
            </VStack>
            <VStack lineHeight={1} alignItems="end">
              <Text fontWeight="bold" fontSize={"lg"}>
                {formattedPrice}
              </Text>
              <Text fontSize="sm" color="gray.400" letterSpacing={1}>
                incl. btw {formattedPriceWithVat}
              </Text>
            </VStack>
          </Flex>
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
