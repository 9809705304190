import { VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import {
  Checkbox,
  useAppDispatch,
  useAppSelector,
  useMoney,
} from "@/modules/common";
import { getSaturdayDeliveryUsabilityThunk } from "@/modules/orders/api";

import {
  selectSaturdayDelivery,
  selectSaturdayDeliveryProduct,
} from "../data/selectors";
import { selectHasSaturdayDeliveryProduct } from "../data/selectors/SelectCanUseSaturdayDelivery";
import { updateSaturdayDeliveryThunk } from "../data/thunks/UpdateSaturdayDeliveryThunk";

export function AddSaturdayDelivery() {
  const [loading, setLoading] = useState(false);
  const saturdayDeliveryProduct = useAppSelector(selectSaturdayDeliveryProduct);
  const [deliveryPrice, setDeliveryPrice] = useState<number>(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSaturdayDeliveryUsabilityThunk());
  }, []);

  useEffect(() => {
    if (!saturdayDeliveryProduct?.price) return;
    setDeliveryPrice(saturdayDeliveryProduct?.price);
  }, [saturdayDeliveryProduct]);

  const formattedSaturdayDeliveryPrice = useMoney(deliveryPrice);

  const hasSaturdayDeliveryProduct = useAppSelector(
    selectHasSaturdayDeliveryProduct
  );
  const saturdayDelivery = useAppSelector(selectSaturdayDelivery);
  const [value, setValue] = useState(!!saturdayDelivery);

  const handleUseSatudayDelivery = async () => {
    setLoading(true);
    await dispatch(updateSaturdayDeliveryThunk());
    setLoading(false);
    setValue(prev => !prev);
  };

  return (
    <VStack w="100%" alignItems="flex-start" mt={6} mb={3}>
      {hasSaturdayDeliveryProduct && (
        <>
          <Checkbox
            label={`Gebruik weekendlevering ${`(${formattedSaturdayDeliveryPrice} ex. btw)`}`}
            isDisabled={loading}
            isChecked={value}
            onChange={() => handleUseSatudayDelivery()}
          />
        </>
      )}
    </VStack>
  );
}
