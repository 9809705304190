import {
  List as ChakraList,
  ListItem as ChakraListItem,
} from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  space: number;
};

export const List = (props: Props) => {
  return (
    <ChakraList display="flex" flexFlow="column" gap={props.space}>
      {props.children}
    </ChakraList>
  );
};

type ListItemProps = {
  children: React.ReactNode;
};

const ListItem = (props: ListItemProps) => {
  return <ChakraListItem>{props.children}</ChakraListItem>;
};

List.Item = ListItem;
