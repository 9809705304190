import { HttpResponse } from "@/modules/common";
import { axios } from "@/modules/common/Axios";
import { HttpResponseWithProblems } from "@/modules/common/HttpResponseWithProblems";

import { BasketDto } from "./BasketDto";
import { GetCustomerBasketDto } from "./dtos/GetCustomerBasketDto";
import { GetSaturdayDeliveryProductDto } from "./dtos/GetSaturdayDeliveryProductDto";
import { UpdateCouponsDto } from "./dtos/UpdateCouponsDto";
import { UpdateOrderItemsDto } from "./dtos/UpdateOrderItemsDto";
import { IBasketApi } from "./IBasketApi";

export class BasketApiImpl implements IBasketApi {
  private static _instance: IBasketApi;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public async load(): Promise<HttpResponse<GetCustomerBasketDto>> {
    return axios.get("/api/v1/orders/basket").then(json => json.data);
  }

  public async getSaturdayDeliveryProductIfApplicable(): Promise<
    HttpResponse<GetSaturdayDeliveryProductDto>
  > {
    return axios
      .get("/api/v1/orders/saturday-delivery")
      .then(json => json.data);
  }

  public async updateOrderItems(
    dto: UpdateOrderItemsDto,
    signal: AbortSignal
  ): Promise<HttpResponseWithProblems<BasketDto>> {
    // TODO: check if this does whats expected (Signal)
    return axios
      .patch("/api/v1/orders/basket/update/order-items", dto, {
        signal,
      })
      .then(json => json.data);
  }

  public async updateCoupons(
    dto: UpdateCouponsDto
  ): Promise<HttpResponseWithProblems<BasketDto>> {
    return axios
      .patch("/api/v1/orders/basket/update/coupons", dto)
      .then(json => json.data);
  }

  public async updateSaturdayDelivery(): Promise<
    HttpResponseWithProblems<BasketDto>
  > {
    return axios
      .patch("/api/v1/orders/basket/update/saturday-delivery")
      .then(json => json.data);
  }

  public async updateBalance(): Promise<HttpResponseWithProblems<BasketDto>> {
    return axios
      .patch("/api/v1/orders/basket/update/balance")
      .then(json => json.data);
  }

  public static getInstance(fake = true): IBasketApi {
    if (!this._instance) {
      this._instance = new BasketApiImpl();
    }

    return this._instance;
  }
}
