import { Middleware } from "redux";

import { createErrorNotification } from "@/modules/notifications/data/createNotification";
import { errorMapper } from "@/modules/notifications/data/ErrorMapper";
import { isHttpProblemDetail } from "@/modules/notifications/data/IsHttpProblemDetail";
import { addNotification } from "@/modules/notifications/data/NotificationsSlice";

export const httpProblemDetailsMiddleware: Middleware =
  store => next => action => {
    if (!action.payload) return next(action);
    if (!isHttpProblemDetail(action.payload)) return next(action);

    store.dispatch(
      addNotification(
        createErrorNotification("Er ging iets mis", errorMapper(action.payload))
      )
    );

    return next(action);
  };
