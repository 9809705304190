import {
  Badge,
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { updateBasketProductsThunk } from "@/modules/basket/data/thunks/UpdateBasketProductsThunk";
import {
  Carousel,
  ReturnToLastPageButton,
  useAppDispatch,
  useMoney,
} from "@/modules/common";
import { ChangeProductAmount } from "@/modules/common/components/ChangeProductAmount";
import { isHttpProblemDetail } from "@/modules/notifications/data/IsHttpProblemDetail";
import { ProductDetailDto, ProductDto, ProductsApi } from "@/modules/products";
import { DetailSkeleton } from "@/modules/products/details/components";

export function ProductDetailsPage() {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const [product, setProduct] = useState<ProductDetailDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const requestRef = useRef<() => void>();

  const formattedPriceWithVat = useMoney(product?.priceVAT || 0);
  const formattedPriceWithoutVat = useMoney(Number(product?.price || 0));

  function updateBasket(product: ProductDto, quantity: number) {
    if (requestRef.current) {
      requestRef.current();
    }

    const req = dispatch(updateBasketProductsThunk({ ...product, quantity }));

    requestRef.current = () => req.abort();
  }

  useEffect(() => {
    setIsLoading(true);
    ProductsApi.detail(slug!)
      .then(data => {
        if (isHttpProblemDetail(data)) return;
        setProduct(data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [slug]);

  if (isLoading) {
    return <DetailSkeleton />;
  }

  if (!product) {
    return <Navigate replace to="/" />;
  }

  return (
    <Container maxW="1400px" pb={8}>
      <ReturnToLastPageButton />
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacingX={16}
        letterSpacing={1}
        pb={6}
      >
        <Carousel
          images={[product.imageUrl!, ...product.additionalImageUrls]}
          video={product.videoUrl}
        />
        <Flex
          flexDir="column"
          gap={{ base: 4, lg: 8 }}
          mb={product.additionalImageUrls.length === 0 ? 4 : 0}
          mt={{ base: 8, lg: 0 }}
          w="full"
        >
          <Box data-cy="name" lineHeight={1.8}>
            {product.remark && (
              <HStack
                mb={{ base: 2, md: 5 }}
                fontWeight="bold"
                fontSize="sm"
                letterSpacing={1}
              >
                <Badge
                  px={2}
                  py={1}
                  color="bsBlack"
                  bg="bsGray.200"
                  borderRadius="5"
                >
                  {product.remark}
                </Badge>
              </HStack>
            )}
            <Text fontSize="2xl" fontWeight="bold" lineHeight={1.3}>
              {product.name}
            </Text>
            <Flex
              gap={2}
              alignItems="center"
              flexDir="row"
              lineHeight={1.2}
              mt={1}
            >
              <Flex>
                <Text fontSize="sm" color="gray.400" pr={1}>
                  Art. Nr:
                </Text>
                <Text fontSize="sm" color="gray.400">
                  {product.articleNumber}
                </Text>
              </Flex>
              {product.ean && (
                <>
                  <Divider orientation="vertical" h="3" />
                  <Flex>
                    <Text fontSize="sm" color="gray.400" pr={1}>
                      EAN:
                    </Text>
                    <Text fontSize="sm" color="gray.400">
                      {product.ean}
                    </Text>
                  </Flex>
                </>
              )}
            </Flex>

            {product.description && (
              <Text
                justifyContent="start"
                fontSize="sm"
                alignItems="start"
                mt={5}
              >
                {product.description}
              </Text>
            )}
          </Box>
          {product.resaleInfo && (
            <>
              <Box lineHeight={1.5}>
                <Text textTransform="uppercase" color="gray.400" fontSize="xs">
                  Consumentenprijs
                </Text>
                <Text fontWeight="bold">{product.resaleInfo}</Text>
              </Box>
            </>
          )}
          <Flex
            w="full"
            justifyContent="space-between"
            alignItems="center"
            my={5}
          >
            <VStack justifyContent="start" alignItems="start" lineHeight={1}>
              <ChangeProductAmount
                {...product}
                updateBasket={item => updateBasket(product, item.amount)}
              />
            </VStack>
            <VStack lineHeight={1} alignItems="end">
              <Text fontWeight="bold" fontSize={"lg"}>
                {formattedPriceWithoutVat}
              </Text>
              <Text fontSize="sm" color="gray.400" letterSpacing={1}>
                incl. btw {formattedPriceWithVat}
              </Text>
            </VStack>
          </Flex>
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
