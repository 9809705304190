import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "@/modules/common";

import { BasketApiImpl, BasketProduct } from "../../api";
import { addProduct, deleteProduct, updateProduct } from "../BasketSlice";

export const updateBasketProductsThunk = createAsyncThunk(
  "basket/update-products",
  (product: BasketProduct, api) => {
    const state = api.getState() as RootState;

    const basketProducts = state.basket.basket.products;

    const inBasket = basketProducts.some(x => x.id === product.id);

    if (!inBasket) {
      api.dispatch(
        addProduct({
          ...product,
          quantity: product.quantity,
        })
      );
    } else if (shouldUpdate(inBasket, product.quantity)) {
      api.dispatch(
        updateProduct({
          id: product.id,
          quantity: product.quantity,
        })
      );
    } else if (shouldDelete(inBasket, product.quantity)) {
      api.dispatch(deleteProduct(product.id));
    }

    // Get the final state and send it to the API
    const newState = api.getState() as RootState;

    return BasketApiImpl.getInstance().updateOrderItems(
      {
        orderItems: newState.basket.basket.products.map(x => ({
          productId: x.id,
          amount: x.quantity,
        })),
      },
      api.signal
    );
  }
);

function shouldUpdate(inBasket: boolean, quantity: number): boolean {
  return inBasket && quantity > 0;
}

function shouldDelete(inBasket: boolean, quantity: number): boolean {
  return inBasket && quantity <= 0;
}
