import { useAppDispatch } from "@/modules/common";

import { setSearchQuery } from "..";
import { useProducts } from ".";

export function useSearch() {
  const dispatch = useAppDispatch();

  const { refetchProducts } = useProducts();

  function onSearch(value: string): void {
    dispatch(setSearchQuery(value));
    refetchProducts();
  }

  return {
    onSearch,
  };
}
