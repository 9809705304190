import { ModuleRouter } from "../common/types";
import { BasketPage } from "./BasketPage";
import { ProtectiveRoute } from "@/modules/common/components/ProtectiveRoute";

export * from "./api";
export * from "./BasketPage";
export * from "./components";
export * from "./data";

export const routerConfig: ModuleRouter = [
  {
    path: "/winkelwagen",
    element: (
      <ProtectiveRoute>
        <BasketPage />
      </ProtectiveRoute>
    ),
  },
];
