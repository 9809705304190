import {
  Divider,
  Flex,
  HStack,
  Skeleton,
  Stack,
  VStack,
} from "@chakra-ui/react";

export function SkeletonCustomer() {
  return (
    <>
      <VStack justify="start" alignItems="start" ml={2} lineHeight={1.5} mb={3}>
        <Stack mb={4}>
          <Skeleton height="16px" width="150px" />
          <Skeleton height="20px" width="150px" />
        </Stack>
      </VStack>
      <Skeleton height="20px" width="200px" />
      <Divider my={3} />
      <HStack justifyContent="space-between">
        <VStack w="30%" alignItems="start" mt={3}>
          <Skeleton height="20px" width="150px" />
          <Skeleton height="35px" width="full" />
        </VStack>
        <VStack w="30%" alignItems="start" mt={3}>
          <Skeleton height="20px" width="150px" />
          <Skeleton height="35px" width="full" />
        </VStack>
        <VStack w="30%" alignItems="start" mt={3}>
          <Skeleton height="20px" width="150px" />
          <Skeleton height="35px" width="full" />
        </VStack>
      </HStack>
      <HStack justifyContent="space-between">
        <VStack w="30%" alignItems="start" mt={3}>
          <Skeleton height="20px" width="150px" />
          <Skeleton height="35px" width="full" />
        </VStack>
        <VStack w="30%" alignItems="start" mt={3}>
          <Skeleton height="20px" width="150px" />
          <Skeleton height="35px" width="full" />
        </VStack>
        <VStack w="30%" alignItems="start" mt={3}>
          <Skeleton height="20px" width="150px" />
          <Skeleton height="35px" width="full" />
        </VStack>
      </HStack>
      <Flex flexDir="column" w="full" gap={4}>
        <VStack alignItems="start" mt={3}>
          <Skeleton height="20px" width="150px" />
          <Skeleton height="35px" width="full" />
        </VStack>
        <VStack alignItems="start" mt={3}>
          <Skeleton height="20px" width="150px" />
          <Skeleton height="35px" width="full" />
        </VStack>
      </Flex>
    </>
  );
}
