import { createSlice } from "@reduxjs/toolkit";

import { BasketDto } from "@/modules/basket";
import { PaymentDto } from "@/modules/orders/api";

export type orderHistory = {
  basket: BasketDto;
  paymentMethod: PaymentDto | undefined;
};

export type HistoryState = {
  orders: orderHistory[];
};

const initialState: HistoryState = {
  orders: [],
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addOrderToHistory(state, { payload }) {
      state.orders.push(payload);
    },
  },
});

export const { addOrderToHistory } = historySlice.actions;
