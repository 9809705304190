import { createAsyncThunk } from "@reduxjs/toolkit";

import { BasketApiImpl } from "../../api/BasketApi";
import { updateBalance } from "../BasketSlice";

export const updateBalanceThunk = createAsyncThunk(
  "basket/update-balance",
  (_, api) => {
    api.dispatch(updateBalance());

    return BasketApiImpl.getInstance().updateBalance();
  }
);
