import { Center, Spinner as ChakraSpinner } from "@chakra-ui/react";

export function Spinner() {
  return (
    <Center w="full" h="full">
      <ChakraSpinner
        thickness="6px"
        emptyColor="gray.200"
        color="bsAccent"
        w="40px"
        h="40px"
        mt={10}
      />
    </Center>
  );
}
