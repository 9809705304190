import { ChangePasswordPage } from "./ChangePasswordPage";
import { CustomerPage } from "./CustomerPage";
import { ModuleRouter } from "@/modules/common";
import { ProtectiveRoute } from "@/modules/common/components/ProtectiveRoute";

export * from "./api";
export * from "./ChangePasswordPage";
export * from "./components";
export * from "./CustomerPage";
export * from "./hooks";

export const customerPageConfiguration: ModuleRouter = [
  {
    path: "/account",
    element: (
      <ProtectiveRoute>
        <CustomerPage />
      </ProtectiveRoute>
    ),
  },
  {
    path: "/account/edit",
    element: (
      <ProtectiveRoute>
        <CustomerPage />
      </ProtectiveRoute>
    ),
  },
  {
    path: "/password",
    element: (
      <ProtectiveRoute>
        <ChangePasswordPage />
      </ProtectiveRoute>
    ),
  },
];
