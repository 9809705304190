import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
} from "@chakra-ui/react";

import { IsActiveFn, OnFilterFn } from "@/modules/products";

interface FiltersProps {
  name: string;
  value: string[];
  onFilter: OnFilterFn;
  isActive: IsActiveFn;
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export function Filters({
  name,
  value,
  isActive,
  onFilter,
  onOpen,
  onClose,
  isOpen,
}: FiltersProps) {
  const hasFilters = Array.isArray(value);

  const formattedFilterName = () => {
    if (name.includes(".")) return name.split(".", 2)[1].trim();
    if (name.includes("_C_")) return name.split("_C_")[1].trim();
    return name;
  };

  const filters = (hasFilters ? value : []).map(val => {
    return (
      <HStack
        key={val}
        p={0}
        py={2}
        _hover={{ cursor: "pointer" }}
        data-testid="filter"
      >
        <div className="checkbox-wrapper">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isActive(name, val)}
              value={name}
              onChange={() => onFilter(name, val)}
            />
            {val}
            <span className="checkmark"></span>
          </label>
        </div>
      </HStack>
    );
  });

  return (
    <AccordionItem
      border="none"
      w="full"
      p={5}
      px={0}
      borderBottom="1px solid"
      borderColor="gray.300"
    >
      <AccordionButton
        data-cy="filtersAccordionButton"
        w="full"
        px={0}
        py={1}
        _hover={{ bg: "transparent" }}
        onClick={() => {
          isOpen ? onClose() : onOpen();
        }}
      >
        <Box flex="1" textAlign="left" fontSize="sm" fontWeight="bold">
          {formattedFilterName()}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel px={0} py={2}>
        {filters}
      </AccordionPanel>
    </AccordionItem>
  );
}
