import { Checkbox as ChakraCheckbox } from "@chakra-ui/react";

type Props = {
  label: string;
  isDisabled: boolean;
  isChecked: boolean;
  onChange: () => void;
};

export const Checkbox = ({ label, isDisabled, isChecked, onChange }: Props) => {
  return (
    <ChakraCheckbox
      isDisabled={isDisabled}
      isChecked={isChecked}
      color="bsBlack"
      size="md"
      onChange={onChange}
    >
      {label}
    </ChakraCheckbox>
  );
};
