import { Button, Flex, Icon } from "@chakra-ui/react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

type Props = {
  text?: string;
};

export function ReturnToLastPageButton({ text = "Verder winkelen" }: Props) {
  const navigate = useNavigate();

  return (
    <Button
      variant="link"
      _hover={{ textDecor: "none" }}
      onClick={() => navigate(-1)}
    >
      <Flex
        display="inline-flex"
        color="#1A202C"
        fontSize="sm"
        fontWeight="light"
        w="content"
        alignContent="center"
        p={4}
        px={0}
      >
        <Icon w={5} h={5} mr={2} as={HiArrowNarrowLeft} />
        {text}
      </Flex>
    </Button>
  );
}
