import { Box, HStack, Text } from "@chakra-ui/react";

import { useMoney } from "@/modules/common";

import { CustomerDto, CustomerDtoAddressDto, NewCustomerDto } from "..";

type Props = CustomerDtoAddressDto<CustomerDto | NewCustomerDto>;

export function CustomerBalance({ balance, syncedAt }: Props) {
  const timestamp = new Date(syncedAt);
  const formattedBalance = useMoney(balance);

  return (
    <HStack justify="start" alignItems="start" ml={2} lineHeight={1.5}>
      <Box>
        {timestamp && (
          <Text fontSize="xs" color="gray.400" letterSpacing={1}>
            Geüpdatet op&nbsp;
            {timestamp.toLocaleString(["nl-NL"], {
              dateStyle: "long",
              timeStyle: "short",
            })}
          </Text>
        )}
        <HStack>
          <Text fontSize="sm" fontWeight="bold" letterSpacing={1}>
            Huidige saldo
          </Text>
          <Text fontSize="sm" letterSpacing={1}>
            {formattedBalance}
          </Text>
        </HStack>
      </Box>
    </HStack>
  );
}
