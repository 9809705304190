import { Flex, VStack } from "@chakra-ui/react";

import { ResetFilters } from "./ResetFilters";

type Props = {
  filters: JSX.Element;
};

export function ProductFilters({ filters }: Props) {
  return (
    <>
      <ResetFilters />
      <Flex
        display={{ base: "none", lg: "flex" }}
        flexDir="column"
        minW="250px"
        data-cy="filtersDrawer"
        zIndex={0}
      >
        <Flex
          h="full"
          w="full"
          flexDir="column"
          pr={1}
          mb={2}
          gap={3}
          alignItems="end"
        >
          <VStack alignItems="start" w="full" h="full">
            {filters}
          </VStack>
        </Flex>
      </Flex>
    </>
  );
}
