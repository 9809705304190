import {
  Box,
  Divider,
  Flex,
  Icon,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CiDeliveryTruck } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

import { AddBalance, Coupons } from "@/modules/basket";
import {
  selectDeliveryCosts,
  selectRawPrice,
  selectSaturdayDelivery,
  selectTotalPrice,
  selectVATAmount,
} from "@/modules/basket/data/selectors";
import { selectBalanceDiscount } from "@/modules/basket/data/selectors/SelectBalanceDiscount";
import OverviewBasketProductsList from "@/modules/checkout/components/OverviewBasketProductsList";
import { CTAButton, Title, useAppSelector, useMoney } from "@/modules/common";
import { LabeledPrice } from "@/modules/common/components/LabeledPrice";

import { selectCouponsDiscount } from "../data/selectors/SelectCouponsDiscount";

type Props = {
  withProducts?: boolean;
  createOrder?: () => void;
  isLoading?: boolean;
  paymentMethod?: string;
};

export function BasketOverview({
  withProducts = false,
  createOrder,
  isLoading = false,
  paymentMethod,
}: Props) {
  const navigate = useNavigate();

  const untilFreeDelivery = 150;
  const rawPrice = useAppSelector(selectRawPrice);
  const totalPrice = useAppSelector(selectTotalPrice);
  const vatAmount = useAppSelector(selectVATAmount);
  const balanceDiscount = useAppSelector(selectBalanceDiscount);
  const couponsDiscount = useAppSelector(selectCouponsDiscount);
  const saturdayDelivery = useAppSelector(selectSaturdayDelivery);
  const deliveryCosts = useAppSelector(selectDeliveryCosts);
  const priceUntilFreeDelivery = useMoney(untilFreeDelivery - rawPrice);

  return (
    <Flex
      w={{ base: "100%", md: "50%", lg: "40%" }}
      flexDir="column"
      bg="bsGray.100"
      p={8}
      gap={4}
      border="1px solid"
      borderColor="gray.200"
      h="fit-content"
      borderRadius="5"
    >
      <Title text="Overzicht" fontSize="md" />
      <Divider />
      <Flex>
        <Coupons />
      </Flex>
      <Flex>
        <AddBalance />
      </Flex>
      {rawPrice < untilFreeDelivery && (
        <Flex gap={4} alignItems="start">
          <Icon as={CiDeliveryTruck} w={8} h={8} />
          <VStack spacing={1} w="full">
            <Text w="full" fontSize="xs">
              Nog {priceUntilFreeDelivery} tot GRATIS verzending
            </Text>
            <Progress
              w="full"
              h="2"
              borderRadius="full"
              value={rawPrice}
              max={untilFreeDelivery}
              bg="white"
            />
          </VStack>
        </Flex>
      )}

      <Box mt="auto">
        {withProducts && <OverviewBasketProductsList />}
        <Flex justifyContent="space-between" flexDir="column" gap={4} mt={8}>
          <LabeledPrice flexRow label="Totaal" price={rawPrice} />
          {!!balanceDiscount && (
            <LabeledPrice
              flexRow
              isDiscount
              label="Gebruikt saldo"
              price={balanceDiscount}
            />
          )}
          {!!couponsDiscount && (
            <LabeledPrice
              flexRow
              isDiscount
              label="Kortingscode(s)"
              price={couponsDiscount}
            />
          )}
          {!!deliveryCosts && (
            <LabeledPrice
              flexRow
              label="Verzend- en handlingskosten"
              price={Number(deliveryCosts.price)}
            />
          )}
          {!!saturdayDelivery && (
            <LabeledPrice
              flexRow
              label="Weekendlevering"
              price={Number(saturdayDelivery.price)}
            />
          )}
          <LabeledPrice flexRow label="btw" price={vatAmount} size="sm" />
          <Divider />

          <LabeledPrice
            flexRow
            boldLabel
            boldPrice
            label="Te betalen"
            price={totalPrice}
            size="sm"
          />
        </Flex>
        {!withProducts && (
          <Flex mt={10} flexDir={{ base: "column", md: "row", lg: "column" }}>
            <CTAButton
              data-cy="goToShipmentButton"
              buttonText={"Bestellen"}
              action={() => navigate("/checkout")}
            />
          </Flex>
        )}
        {withProducts && (
          <CTAButton
            width={"100%"}
            order={{ md: "2", sm: "1" }}
            marginTop={6}
            alignSelf="end"
            type="submit"
            disabled={isLoading || !paymentMethod}
            isLoading={isLoading}
            buttonText="Bestelling betalen"
            action={createOrder}
          />
        )}
      </Box>
    </Flex>
  );
}
