import {
  Button,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";

type Props = {
  placeholder: string;
  register: UseFormRegisterReturn;
  isInvalid: boolean;
  errorMessage?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function CustomPasswordField({
  placeholder,
  isInvalid,
  register,
  onBlur,
  errorMessage,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl w="full" isInvalid={isInvalid}>
      <InputGroup>
        <Input
          data-private
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
          p={4}
          w="full"
          fontSize="sm"
          bg="white"
          borderRadius={5}
          size="md"
          data-cy="passwordField"
          {...register}
          _focusVisible={{
            border: isInvalid ? "1px solid" : "3px solid",
            borderColor: isInvalid ? "red" : "bsBlack",
          }}
          onBlur={onBlur}
        />
        <InputRightElement width="3rem">
          <Button
            backgroundColor="transparent"
            data-cy="showPassword"
            _hover={{ bg: "transparent" }}
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon
              h={6}
              w={6}
              as={showPassword ? FaEye : FaEyeSlash}
              color="bsBlack"
            />
          </Button>
        </InputRightElement>
      </InputGroup>
      {errorMessage && (
        <Text color="#E53E3E" fontSize="xs" mt={1} ml={2}>
          {errorMessage}
        </Text>
      )}
    </FormControl>
  );
}
