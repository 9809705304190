import { ModuleRouter } from "../common/types";
import { NotFoundPage } from "./NotFoundPage";
import { ProtectiveRoute } from "@/modules/common/components/ProtectiveRoute";

export * from "./components";
export * from "./hooks";
export * from "./NotFoundPage";
export * from "./Store";
export * from "./types";
export * from "./utils";

export const notFoundPageRouterConfig: ModuleRouter = [
  {
    path: "*",
    element: (
      <ProtectiveRoute>
        <NotFoundPage />
      </ProtectiveRoute>
    ),
  },
];
