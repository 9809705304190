import {
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { CTAButton } from "@/modules/common/components";

import NotFound from "../../assets/404-not-found.png";

export function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <Container maxW="1400px" pb={8}>
      <Flex
        bg="white"
        mt={10}
        py={{ base: 8, md: 10 }}
        px={{ base: 10, md: 16 }}
        flexDir={{ base: "column-reverse", md: "row" }}
      >
        <VStack alignItems="flex-start" flex={1} justifyContent="center">
          <Heading as="h2" fontWeight="bold">
            Oeps!
          </Heading>
          <Heading as="h3" fontSize={{ base: "1em", md: "1.5em" }}>
            Sorry, we kunnen deze pagina niet meer vinden
          </Heading>
          <Text>
            Het lijkt erop dat deze pagina niet bestaat of misschien is
            verhuisd. Je kunt altijd terugkeren waar je vandaan komt of naar de
            homepage gaan.
          </Text>
          <HStack pt={3}>
            <CTAButton buttonText="Ga terug" action={() => navigate(-1)} />
            <CTAButton buttonText="Home" action={() => navigate("/")} />
          </HStack>
        </VStack>
        <Flex flex={1}>
          <Image
            src={NotFound}
            alt="Beauty District Logo"
            w={{ base: "350px", md: "650px" }}
            objectFit="contain"
          />
        </Flex>
      </Flex>
    </Container>
  );
}
