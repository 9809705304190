import { createAsyncThunk } from "@reduxjs/toolkit";

import { BasketApiImpl } from "../../api";

export const updateCouponsThunk = createAsyncThunk(
  "basket/update-coupons",
  async (coupons: string[]) => {
    return BasketApiImpl.getInstance().updateCoupons({
      coupons,
    });
  }
);
