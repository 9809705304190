import { OrderCheckoutFailurePage } from "@/modules/orders/OrderCheckoutFailurePage";
import { OrderCheckoutSuccessPage } from "@/modules/orders/OrderCheckoutSuccessPage";

import { ModuleRouter, ProtectedRoute } from "../common";

export * from "./OrderCheckoutSuccessPage";

export const orderResponseRouterConfig: ModuleRouter = [
  {
    path: "/checkout/success",
    element: (
      <ProtectedRoute>
        <OrderCheckoutSuccessPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/checkout/failure",
    element: (
      <ProtectedRoute>
        <OrderCheckoutFailurePage />
      </ProtectedRoute>
    ),
  },
];
