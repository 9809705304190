import {
  CustomerDto,
  OldUpdateCustomerDto,
  UpdateCustomerDto,
} from "@/modules/auth";
import { HttpProblemDetails } from "@/modules/common";
import { axios } from "@/modules/common/Axios";

export type CustomerNotFoundProblemDetails = HttpProblemDetails<{
  additionalInfo: string;
}>;

type GetCustomersResponse =
  | { data: CustomerDto }
  | CustomerNotFoundProblemDetails;

type UpdateCustomerResponse =
  | { data: CustomerDto }
  | CustomerNotFoundProblemDetails;

export type PaymentProviderOptionsDto = {
  name: string;
  value: string;
  allowed: boolean;
};

type GetPaymentProviderOptionsResponse = { data: PaymentProviderOptionsDto[] };

export class CustomersApi {
  public static async get(): Promise<GetCustomersResponse> {
    return axios.get(`/api/v1/customers/me`).then(({ data }) => data);
  }
  public static async update(
    data: OldUpdateCustomerDto | UpdateCustomerDto
  ): Promise<UpdateCustomerResponse> {
    return axios.patch(`/api/v1/customers`, data).then(({ data }) => data);
  }

  public static getPaymentOptions(): Promise<GetPaymentProviderOptionsResponse> {
    return axios
      .get("/api/v1/customers/payment-options")
      .then(({ data }) => data);
  }
}
