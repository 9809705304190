import { Middleware } from "redux";

import { createErrorNotification } from "@/modules/notifications/data/createNotification";
import { addNotification } from "@/modules/notifications/data/NotificationsSlice";

export const rejectedPromiseMiddleware: Middleware =
  store => next => action => {
    if (!action.type.includes("rejected")) {
      return next(action);
    }

    if (action.error && action.error?.name === "AbortError") {
      return next(action);
    }

    const errorNotification = createErrorNotification(
      "Er is iets onbekends fout gegaan",
      "Indien dit voorkomt gelieve een administrator te contacteren"
    );

    console.error({ action });
    store.dispatch(addNotification(errorNotification));

    return next(action);
  };
