import { v4 } from "uuid";

import { Notification } from "./Notification";

function createNotification(status: Notification["status"]) {
  return (title: string, description: string) => ({
    id: v4(),
    title,
    description,
    status,
  });
}

export const createSuccessNotification = createNotification("success");
export const createInfoNotification = createNotification("info");
export const createErrorNotification = createNotification("error");
