import { ModuleRouter } from "@/modules/common";

import { LoginPage } from "./LoginPage";
import { ResetPasswordPage } from "./ResetPasswordPage";

export * from "./api";
export * from "./data";
export * from "./LoginPage";
export * from "./ResetPasswordPage";

export const authPageRouterConfig: ModuleRouter = [
  {
    path: "/inloggen",
    element: <LoginPage />,
  },
  {
    path: "/reset",
    element: <ResetPasswordPage />,
  },
];
