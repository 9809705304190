import { Tag } from "..";

export type TagDto = {
  isDefault: boolean;
  name: string;
  values: string[];
};

export function tagsAreEqual(a: Tag) {
  return (b: TagDto) => b.name === a.category && b.values.includes(a.value);
}
