import { Container, Flex } from "@chakra-ui/react";

import {
  EmptyListMessage,
  ReturnToLastPageButton,
  Spinner,
  useAppSelector,
} from "../common";
import { BasketList } from "./components/BasketList";
import { BasketOverview } from "./components/BasketOverview";
import { selectIsEmptyBasket, selectIsLoadingBasket } from "./data/selectors";

export function BasketPage() {
  const isEmpty = useAppSelector(selectIsEmptyBasket);
  const isLoading = useAppSelector(selectIsLoadingBasket);

  return (
    <Container maxW="1400px" pb={8}>
      <ReturnToLastPageButton />
      {!isEmpty && !isLoading && (
        <Flex
          flexDir={{ base: "column", md: "row" }}
          letterSpacing={1}
          pb={6}
          gap={6}
        >
          <BasketList />
          <BasketOverview />
        </Flex>
      )}
      {!isLoading && isEmpty && (
        <EmptyListMessage message="Er zijn momenteel geen producten in uw winkelwagen" />
      )}
      {isLoading && <Spinner />}
    </Container>
  );
}
