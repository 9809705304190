import { useRef } from "react";

import { useAppDispatch } from "@/modules/common";

import { FETCH_LIMIT, fetchProducts } from "..";

export function useProducts() {
  const dispatch = useAppDispatch();
  const requestRef = useRef<() => void>();

  function refetchProducts(page = 1): void {
    if (requestRef.current) {
      requestRef.current();
    }

    const req = dispatch(
      fetchProducts({
        limit: FETCH_LIMIT,
        page: page,
      })
    );

    requestRef.current = () => req.abort();
  }

  return {
    refetchProducts,
  };
}
