import { useAppDispatch } from "@/modules/common";
import { useNotifications } from "@/modules/notifications/hooks/UseNotifications";

import {
  addAltAddress,
  OldUpdateCustomerDto,
  resetIsUpdated,
  UpdateCustomerDto,
} from "..";
import { updateCustomer } from "../api/CustomerUpdateThunk";
import { fetchCustomer } from "../api/FetchCustomerThunk";

export function useCustomer() {
  const dispatch = useAppDispatch();
  const notification = useNotifications();

  function getCustomer(): void {
    dispatch(fetchCustomer());
  }

  function changeCustomer(
    data: OldUpdateCustomerDto | UpdateCustomerDto
  ): void {
    dispatch(updateCustomer(data))
      .unwrap()
      .then(() => {
        getCustomer();
        notification.success(
          "Gegevens opgeslagen",
          "De aangepaste gegevens zijn succesvol opgeslagen"
        );
      });
  }

  function addAlternativeAddress(data: UpdateCustomerDto | undefined): void {
    dispatch(addAltAddress(data));
  }

  function resetIsUpdatedAfterSuccess() {
    dispatch(resetIsUpdated());
  }

  return {
    getCustomer,
    changeCustomer,
    addAlternativeAddress,
    resetIsUpdatedAfterSuccess,
  };
}
