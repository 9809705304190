import { Flex, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import {
  selectCanUseBalance,
  selectUseBalance,
} from "@/modules/basket/data/selectors";
import { useAppDispatch, useAppSelector, useMoney } from "@/modules/common";
import { Checkbox } from "@/modules/common/components/Checkbox";
import { selectCustomer } from "@/modules/customers";

import { updateBalanceThunk } from "../data/thunks/UpdateBalanceThunk";

export function AddBalance() {
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);

  const dispatch = useAppDispatch();
  const canUseBalance = useAppSelector(selectCanUseBalance);
  const { data } = useAppSelector(selectCustomer);
  const basketBalance = useAppSelector(selectUseBalance);

  useEffect(() => {
    if (!data?.balance) return;
    setBalance(data.balance);
  }, []);

  const formattedBalance = useMoney(balance);

  const handleUseBalance = async () => {
    setLoading(true);
    await dispatch(updateBalanceThunk());
    setLoading(false);
  };

  const isDisabled = loading || !canUseBalance;

  return (
    <VStack w="100%" alignItems="flex-start" mb={3}>
      <Flex flexDir="column">
        <Checkbox
          label={`Gebruik saldo`}
          isChecked={basketBalance}
          isDisabled={isDisabled}
          onChange={() => handleUseBalance()}
        />
        <Text fontSize="xs" color="gray.600" pl={6}>
          Huidig saldo {formattedBalance} excl. btw
        </Text>
      </Flex>
      {!canUseBalance && balance > 0 && (
        <Text
          fontSize="xs"
          fontStyle="italic"
          color="gray.400"
          letterSpacing={1}
        >
          De winkelwagen bevat geen producten waarvoor saldo gebruikt kan worden
        </Text>
      )}
    </VStack>
  );
}
