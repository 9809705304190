import { initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";

// TODO: Move to ENV and setup proper SA
export function getServiceAccount() {
  if (process.env.VITE_ENV === "production") {
    return {
      apiKey: "AIzaSyAi3Szv418prdKlm8zfyJL30d9_BOfXANE",
      authDomain: "exact-shop.firebaseapp.com",
      projectId: "exact-shop",
      storageBucket: "exact-shop.appspot.com",
      messagingSenderId: "969819592348",
      appId: "1:969819592348:web:ca85e088e03310d4c2ff59",
    };
  }

  return {
    apiKey: "AIzaSyC75_neS_e9YhNKFFmt1h6ES5UUs41jgwo",
    authDomain: "staging-exact-shop.firebaseapp.com",
    projectId: "staging-exact-shop",
    storageBucket: "staging-exact-shop.appspot.com",
    messagingSenderId: "854409263909",
    appId: "1:854409263909:web:18307d4cb84ab0ea0e38cc",
  };
}

export const app = initializeApp(getServiceAccount());

export const auth = getAuth(app);

if (process.env.VITE_ENV === "development" || process.env.VITE_ENV === "test") {
  connectAuthEmulator(auth, "http://localhost:9099");
}

if (process.env.VITE_ENV === "test") {
  createUserWithEmailAndPassword(auth, "john@zzapps.nl", "asdasd")
    .catch(_ => null)
    .finally(() => {
      signInWithEmailAndPassword(auth, "john@zzapps.nl", "asdasd");
    });
}
