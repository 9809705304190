import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";

import bsLogo from "../../../../assets/bs-logo.png";
import { ShoppingCart, UserMenu } from ".";

export function Heading() {
  return (
    <Container
      maxW="full"
      position="fixed"
      top={{ base: "0", md: "48px" }}
      backgroundColor="white"
      zIndex={2}
      boxShadow="sm"
      px={0}
      h="content"
      py={{ base: "4", md: "0" }}
    >
      <Container maxW="1400px" h="full">
        <Flex
          display="flex"
          justifyContent="space-between"
          px={0}
          alignItems="center"
          h="full"
          pb={{ base: "0", md: "3" }}
          flexWrap="wrap"
        >
          <HStack
            gap={1}
            display={{ base: "none", md: "flex" }}
            justifyContent="start"
          >
            <Link
              isExternal
              href={"https://www.instagram.com/beautydistrictlab/"}
            >
              <Icon
                as={FaInstagram}
                mt={2}
                w={6}
                h={6}
                color="#5e5753"
                verticalAlign="center"
              />
            </Link>
            <Link isExternal href={"https://www.facebook.com/BeautydistrictBV"}>
              <Icon as={FaFacebookF} mt={2} w={5} h={5} color="#5e5753" />
            </Link>

            <Link
              isExternal
              href={"https://www.linkedin.com/company/beautydistrict-b-v-/"}
            >
              <Icon as={FaLinkedin} mt={2} w={6} h={6} color="#5e5753" />
            </Link>
          </HStack>

          <HStack color="#978984" display={{ base: "none", md: "block" }}>
            <Link as={RouterLink} to="/">
              <Image
                src={bsLogo}
                alt="Beauty District Logo"
                h={{ base: "32px", md: "52px" }}
                objectFit="contain"
              />
            </Link>
          </HStack>
          <Menu>
            <MenuButton
              display={{ base: "flex", md: "none" }}
              as={Button}
              rightIcon={<ChevronDownIcon />}
              textTransform="uppercase"
              fontWeight="bold"
              color="#5e5753"
              bg="none"
              p={0}
              letterSpacing={1.6}
              _hover={{ bg: "none" }}
              _active={{ bg: "none" }}
            >
              <Image
                src={bsLogo}
                alt="Beauty District Logo"
                h="28px"
                objectFit="contain"
              />
            </MenuButton>
            <MenuList>
              <MenuItem
                textTransform="uppercase"
                fontWeight="bold"
                color="#5e5753"
                bg="none"
                letterSpacing={1.6}
                _hover={{ bg: "none" }}
                _active={{ bg: "none" }}
                py={3}
                px={5}
              >
                <Link
                  as={RouterLink}
                  to={"/"}
                  _hover={{ textDecoration: "none" }}
                >
                  Home
                </Link>
              </MenuItem>
              <MenuItem
                textTransform="uppercase"
                fontWeight="bold"
                color="#5e5753"
                bg="none"
                letterSpacing={1.6}
                _hover={{ bg: "none" }}
                _active={{ bg: "none" }}
                py={3}
                px={5}
              >
                <Link
                  href="https://www.beautydistrict.nl/"
                  _hover={{ textDecoration: "none" }}
                >
                  BeautyDistrict
                </Link>
              </MenuItem>
              <MenuItem
                textTransform="uppercase"
                fontWeight="bold"
                color="#5e5753"
                bg="none"
                letterSpacing={1.6}
                _hover={{ bg: "none" }}
                _active={{ bg: "none" }}
                py={3}
                pl={5}
              >
                <Link
                  href="https://www.beautydistrict.nl/educatie/"
                  _hover={{ textDecoration: "none" }}
                >
                  Educatie
                </Link>
              </MenuItem>
            </MenuList>
          </Menu>
          <HStack alignItems="flex-end">
            <UserMenu />
            <ShoppingCart />
          </HStack>
        </Flex>
      </Container>
    </Container>
  );
}
