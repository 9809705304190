import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  CustomersApi,
  OldUpdateCustomerDto,
  UpdateCustomerDto,
} from "@/modules/customers";

export const updateCustomer = createAsyncThunk(
  "customers",
  (data: OldUpdateCustomerDto | UpdateCustomerDto) => {
    return CustomersApi.update(data);
  }
);
