import { Navigate, useLocation } from "react-router-dom";

import { selectIsAuthenticated } from "@/modules/auth/data/selectIsAuthenticated";
import { Layout } from "@/modules/layout";

import { useAppSelector } from "../Store";

type Props = {
  children: JSX.Element;
};

export function ProtectedRoute({ children }: Props) {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { pathname } = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={{ pathname: "/inloggen", search: `redirect=${pathname}` }}
      />
    );
  }

  return <Layout>{children}</Layout>;
}
