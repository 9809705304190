import { Flex, Text } from "@chakra-ui/react";
import { useMoney } from "@/modules/common";

type Props = {
  label: string;
  price: number;
  boldLabel?: boolean;
  boldPrice?: boolean;
  size?: string;
  isDiscount?: boolean;
  flexRow?: boolean;
};

export function LabeledPrice({
  boldLabel,
  boldPrice,
  flexRow,
  label,
  price,
  size,
  isDiscount,
}: Props) {
  const formattedPrice = useMoney(Number(price));

  return (
    <Flex
      flexDir={flexRow ? "row" : "column"}
      justifyContent="space-between"
      {...(flexRow && { width: "100%" })}
    >
      <Text
        {...(boldLabel && { fontWeight: "bold" })}
        {...(isDiscount && { color: "bsBlack" })}
        fontSize={size ? size : "sm"}
      >
        {label}
      </Text>
      <Text
        {...(boldPrice && { fontWeight: "bold" })}
        {...(isDiscount && { color: "bsBlack" })}
        fontSize={size ? size : "sm"}
        alignSelf="flex-end"
      >
        {isDiscount && "- "}
        {formattedPrice}
      </Text>
    </Flex>
  );
}
