import { createSlice } from "@reduxjs/toolkit";

import { Status } from "@/modules/common";
import { isHttpProblemDetail } from "@/modules/notifications/data/IsHttpProblemDetail";
import { CreateOrderDto } from "@/modules/orders/api/CreateOrderDto";

import { createOrder } from "./CreateOrderThunk";

export type OrderStatus = Status;

export type OrderState = {
  data: CreateOrderDto | null;
  transactionUrl: string | undefined;
  error: string | null;
  status: OrderStatus;
  statusCode: number | null;
  usable: boolean;
  hasSaturdayDeliveryProduct: boolean;
};

const initialState: OrderState = {
  data: null,
  transactionUrl: undefined,
  error: null,
  status: "idle",
  statusCode: null,
  usable: false,
  hasSaturdayDeliveryProduct: false,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearOrder(state) {
      state.status = "idle";
      state.statusCode = null;
    },
    setSaturdayDeliveryProduct(state, { payload }) {
      state.hasSaturdayDeliveryProduct = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createOrder.pending, state => {
        state.status = "loading";
      })
      .addCase(createOrder.fulfilled, (state, { payload }) => {
        if (isHttpProblemDetail(payload)) {
          state.status = "error";
          return;
        }

        if ("transactionUrl" in payload.data) {
          state.transactionUrl = payload.data.transactionUrl;
        }

        state.statusCode = payload.data.status;

        state.error = null;
        state.status = "success";
      })
      .addCase(createOrder.rejected, state => {
        state.error = "Onze excuses er ging iets mis.";
        state.data = null;
        state.status = "success";
      });
  },
});

export const { clearOrder, setSaturdayDeliveryProduct } = ordersSlice.actions;
