import { HStack, Link } from "@chakra-ui/react";

export function Banner() {
  return (
    <HStack
      gap={5}
      display={{ base: "none", md: "flex" }}
      justifyContent="center"
      position="fixed"
      top="0"
      w="full"
      zIndex={2}
      bg="white"
      pt="4"
      pb="2"
    >
      <Link
        href={"https://www.beautydistrict.nl/"}
        fontWeight="bold"
        textTransform="uppercase"
        letterSpacing={1.2}
        color="#5e5753"
        _hover={{ textDecor: "none" }}
      >
        Website
      </Link>
      <Link
        href={"https://www.beautydistrict.nl/educatie/"}
        fontWeight="bold"
        textTransform="uppercase"
        letterSpacing={1.2}
        color="#5e5753"
        _hover={{ textDecor: "none" }}
      >
        Educatie
      </Link>
    </HStack>
  );
}
