import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Notification } from "@/modules/notifications/data/Notification";

type NotificationsState = {
  notifications: Notification[];
};

const initialState: NotificationsState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<Notification>) {
      if (state.notifications.some(x => x.id === action.payload.id)) return;

      state.notifications.push(action.payload);
    },
    removeNotification(state, action: PayloadAction<Notification>) {
      state.notifications = state.notifications.filter(
        x => x.id !== action.payload.id
      );
    },
    clearNotifications(state) {
      state.notifications = [];
    },
  },
});

export const notificationsReducer = notificationsSlice.reducer;
export const { addNotification, clearNotifications } =
  notificationsSlice.actions;
