import { Button } from "@chakra-ui/react";

type Props = {
  text: string;
  onClick: () => void;
  variant: "primary";
};

export function TextButton({ text, onClick, variant = "primary" }: Props) {
  const capitalizedText = text.charAt(0).toUpperCase() + text.slice(1);

  if (variant === "primary") {
    return (
      <Button
        variant="link"
        color="bsBlack"
        fontWeight="normal"
        fontSize="sm"
        onClick={onClick}
      >
        {capitalizedText}
      </Button>
    );
  }

  return null;
}
