import {
  Badge,
  Box,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";

import { OrderItem } from "@/modules/basket";
import { useMoney } from "@/modules/common";
import { ChangeProductAmount } from "@/modules/common/components/ChangeProductAmount";

import fallbackImage from "../../../assets/bd-placeholder.svg";
import { ProductDto } from "..";

type Props = ProductDto & {
  updateBasket(item: OrderItem): void;
};

export function Product(props: Props) {
  const { updateBasket, ...product } = props;
  const { imageUrl, name, price, slug, series, priceVAT, additionalImageUrls } =
    product;
  const priceWithoutVat = useMoney(Number(price));
  const priceWithVat = useMoney(priceVAT);

  const detailLink = series
    ? `/producten/series/${series}/${slug}`
    : `/producten/${slug}`;

  function getMainImage(isPartialNameMatch: boolean): string | undefined {
    if (isPartialNameMatch) {
      return additionalImageUrls && additionalImageUrls.length
        ? additionalImageUrls[0]!
        : fallbackImage;
    }

    return imageUrl;
  }

  const isSeriesProduct = Boolean(product.id.includes("series_"));
  const showTitle = !isSeriesProduct;
  const showButton = !isSeriesProduct;
  const mainImage = getMainImage(!!isSeriesProduct);

  const getBrandColor = () => {
    const brandColors: { [key: string]: string } = {
      Moroccanoil: "#01babf",
      Oligo: "#e31c53",
      Mydentity: "#4c2b62",
    };

    return brandColors[product.brand] || "black";
  };

  return (
    <Flex
      bg="bsWhite"
      border="1px solid"
      borderColor="bsGray.200"
      w="full"
      data-cy="product"
      data-testid="product"
      flexDir={{ base: "column" }}
      alignItems="center"
      borderRadius="5"
      gap={6}
      zIndex={0}
    >
      <Link as={ReactLink} to={detailLink} data-cy="productLink" w="full">
        <Flex
          w="full"
          justifyContent="center"
          p={12}
          bg="bsGray.100"
          position="relative"
        >
          <Image
            w="full"
            h={{ base: 100, md: 150 }}
            fit="contain"
            src={mainImage}
            fallbackSrc={fallbackImage}
            alt={name}
            blendMode="multiply"
            textAlign="center"
          />
          {product.remark && (
            <Box position="absolute" top={2} right={-1}>
              <Box
                position="absolute"
                w={0}
                h={0}
                border="1px solid transparent"
                borderWidth={4}
                borderBottom={0}
                borderLeft={0}
                borderTopStyle="solid"
                borderTopColor={getBrandColor()}
                borderTopWidth={4}
                right={0}
                bottom={-1}
              />
              <Badge
                px={2}
                py={1}
                color="bsWhite"
                bg={getBrandColor()}
                borderLeftRadius={5}
                borderBottomRightRadius={0}
                letterSpacing={1}
                fontSize="2xs"
                shadow="md"
                fontWeight="normal"
              >
                {product.remark}
              </Badge>
            </Box>
          )}
        </Flex>
      </Link>

      <Flex
        justifyContent="space-between"
        flexDir="column"
        h="full"
        w="full"
        p={5}
        pt={0}
      >
        <Link as={ReactLink} to={detailLink}>
          <Box flex={1}>
            {series && (
              <Text
                textAlign="left"
                fontSize="sm"
                textTransform="uppercase"
                fontWeight="bold"
                letterSpacing={1}
                mb={1}
              >
                {series}
              </Text>
            )}
            {showTitle && (
              <Text
                textAlign="left"
                letterSpacing={1}
                fontWeight="medium"
                fontSize="sm"
              >
                {name}
              </Text>
            )}
          </Box>
        </Link>

        <VStack mt={5}>
          <HStack w="100%" justifyContent="space-between" alignItems="end">
            {showTitle && (
              <Box>
                <Text fontSize="sm" fontWeight="bold" letterSpacing={1}>
                  {priceWithoutVat}
                </Text>
                <Text fontSize="xs" color="gray.400" letterSpacing={1} mt={0}>
                  {priceWithVat} incl. btw
                </Text>
              </Box>
            )}

            {showButton && (
              <ChangeProductAmount
                {...product}
                updateBasket={item => updateBasket(item)}
              />
            )}
            {!showButton && (
              <Link
                as={ReactLink}
                to={detailLink}
                bg="bsBlack"
                color="bsWhite"
                fontWeight="regular"
                fontSize="sm"
                borderRadius={5}
                px={5}
                w="content"
                h={10}
                lineHeight={10}
                _hover={{
                  bg: "bsBlack",
                  _disabled: {
                    opacity: 0.4,
                  },
                }}
                _active={{
                  bg: "bsBlack",
                }}
              >
                Naar serie
              </Link>
            )}
          </HStack>
        </VStack>
      </Flex>
    </Flex>
  );
}
