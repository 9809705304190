import { AspectRatio, HStack, Image, Text } from "@chakra-ui/react";

type Props = {
  image: string | undefined;
  label: string | undefined;
  isActive: boolean;
  onClick: () => void;
};

export function ColorChip({ image, label, isActive, onClick }: Props) {
  return (
    <HStack
      h="52px"
      px={2}
      border="1px solid"
      borderColor={isActive ? "bsAccent" : "gray.200"}
      borderRadius="5"
      w="full"
      _hover={{ cursor: "pointer" }}
      onClick={onClick}
    >
      {image && (
        <AspectRatio ratio={1} minW="32px">
          <Image src={image} alt={label} style={{ objectFit: "contain" }} />
        </AspectRatio>
      )}
      <Text
        px={2}
        fontSize="sm"
        color={isActive ? "bsAccent" : "gray.400"}
        wordBreak="break-word"
        noOfLines={2}
      >
        {label}
      </Text>
    </HStack>
  );
}
