import { CheckIcon } from "@chakra-ui/icons";
import { Container, Flex, HStack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { selectBasket } from "@/modules/basket/data/selectors/SelectBasket";
import { loadBasketThunk } from "@/modules/basket/data/thunks/LoadBasketThunk";
import { Payment, Shipping } from "@/modules/checkout/components";
import { addOrderToHistory } from "@/modules/history/api";
import { clearOrder, PaymentDto, selectOrder } from "@/modules/orders/api";
import { useOrder } from "@/modules/orders/hooks";

import { BasketOverview } from "../basket";
import { selectTotalPrice } from "../basket/data/selectors";
import {
  ReturnToLastPageButton,
  useAppDispatch,
  useAppSelector,
} from "../common";
import { createOrder } from "../orders/api/CreateOrderThunk";
import { Remark } from "./components/Remark";

export function CheckoutPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const totalPrice = useAppSelector(selectTotalPrice);
  const { transactionUrl, statusCode } = useAppSelector(selectOrder);
  const isAllowedToVisitPage = useAppSelector(
    x => x.basket.basket && x.basket.basket.products.length > 0
  );
  const basket = useAppSelector(selectBasket);
  const { status } = useOrder();
  const [paymentMethod, setPaymentMethod] = useState<PaymentDto | undefined>(
    undefined
  );
  const [remarks, setRemarks] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const error = status === "error";
  const hasTotalPrice = totalPrice > 0;

  useEffect(() => {
    if (!isAllowedToVisitPage && !transactionUrl) {
      navigate("/", {
        replace: true,
      });
    }
  }, [isAllowedToVisitPage]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }

    if (!transactionUrl && statusCode !== 204) return;

    if (statusCode === 204) {
      navigate("/checkout/success");
    }

    if (transactionUrl) {
      window.location.href = transactionUrl;
    }

    dispatch(addOrderToHistory({ basket, paymentMethod }));
    dispatch(loadBasketThunk());
    dispatch(clearOrder());
  }, [transactionUrl, statusCode]);

  useEffect(() => {
    if (hasTotalPrice) return;
    setPaymentMethod("Invoice");
  }, [totalPrice]);

  const onCreateOrder = () => {
    setIsLoading(true);
    dispatch(createOrder({ paymentMethod, remarks }));
  };

  return (
    <Container maxW="1400px" pb={8}>
      <HStack w="full" justifyContent="space-between">
        <ReturnToLastPageButton text="Terug naar winkelwagen" />
      </HStack>

      <Flex
        w="100%"
        flexDir={{ base: "column", md: "row" }}
        letterSpacing={1}
        pb={6}
        gap={6}
      >
        <Flex
          flexDirection="column"
          w={{ base: "100%", md: "50%", lg: "60%" }}
          border="1px solid"
          borderColor="gray.200"
          borderRadius={5}
          p={8}
          gap={4}
        >
          <Shipping />
          {hasTotalPrice && (
            <Payment
              setPaymentMethod={setPaymentMethod}
              paymentMethod={paymentMethod}
            />
          )}
          <Remark setRemarks={setRemarks} />
        </Flex>
        <BasketOverview
          withProducts
          createOrder={onCreateOrder}
          isLoading={isLoading}
          paymentMethod={paymentMethod}
        />
      </Flex>
    </Container>
  );
}
