import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Status } from "@/modules/common";

import { ProductDetailDto } from "..";
import {
  errorMapper,
  fetchProducts,
  loadMoreProducts,
  ProductDto,
  TagDto,
  tagsAreEqual,
} from ".";
import { ActiveFilter } from "./ActiveFilter";

export type ProductStatus = Status;

export type ProductState = {
  data: ProductDto[];
  hasMore: boolean;
  currentPage: number;
  search?: string;
  error: string | null;
  tags: TagDto[];
  activeFilters: ActiveFilter[];
  status: ProductStatus;
  detail: ProductDetailDto | null;
  totalHits: number;
};

const initialState: ProductState = {
  data: [],
  hasMore: true,
  error: null,
  currentPage: 1,
  search: "",
  tags: [],
  activeFilters: [],
  status: "loading",
  detail: null,
  totalHits: 0,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setActiveFilters(state, { payload }) {
      state.currentPage = 1;
      state.data = [];

      const index = state.activeFilters.findIndex(
        x => x.category === payload.category && x.value === payload.value
      );

      const tag = state.tags.find(tagsAreEqual(payload));

      if (index <= -1) {
        state.activeFilters.push({
          ...payload,
          isDefault: tag?.isDefault ?? false,
        });
        return;
      }

      state.activeFilters.splice(index, 1);
    },
    hydrateFilters(state, { payload }: PayloadAction<ActiveFilter[]>) {
      state.activeFilters = payload;
    },
    resetActiveFilters(state) {
      state.currentPage = 1;
      state.activeFilters = [];
      state.data = [];
    },
    setSearchQuery(state, { payload }) {
      state.currentPage = 1;
      state.data = [];
      state.search = payload;
      state.activeFilters = [];
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, { payload }) => {
        if ("type" in payload) {
          state.data = [];
          state.error = errorMapper(payload);
          state.currentPage = 1;
          state.status = "error";
          return state;
        }

        state.tags = payload.tags;

        state.totalHits = payload.totalHits;
        state.data = payload.data;
        state.hasMore = payload.hasMore;
        state.error = null;
        state.status = "idle";
      })
      .addCase(fetchProducts.rejected, state => {
        state.error = "Onze excuses er ging iets mis.";
        state.totalHits = 0;
        state.data = [];
        state.currentPage = 1;
        state.status = "error";
      })
      .addCase(loadMoreProducts.pending, state => {
        state.status = "loading";
      })
      .addCase(loadMoreProducts.fulfilled, (state, { payload }) => {
        if ("type" in payload) {
          state.data = [];
          state.error = errorMapper(payload);
          state.currentPage = 1;
          state.status = "error";
          return state;
        }

        state.totalHits = payload.totalHits;
        state.currentPage += 1;
        state.data.push(...payload.data);
        state.hasMore = payload.hasMore;
        state.error = null;
        state.status = "idle";
      })
      .addCase(loadMoreProducts.rejected, state => {
        state.error = "Onze excuses er ging iets mis.";
        state.totalHits = 0;
        state.data = [];
        state.currentPage = 1;
        state.status = "error";
      });
  },
});

export const {
  setActiveFilters,
  resetActiveFilters,
  hydrateFilters,
  setSearchQuery,
  setCurrentPage,
} = productsSlice.actions;
