export function useMoney(price: number) {
  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  }).format(price);
}

export function round(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
