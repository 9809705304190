import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { selectBasketProducts } from "@/modules/basket/data/selectors";
import { LabeledPrice, useAppSelector } from "@/modules/common";

export default function OverviewBasketProductsList() {
  const products = useAppSelector(selectBasketProducts);

  return (
    <Accordion
      allowToggle
      width="100%"
      fontSize="sm"
      data-cy="shortShoppingCartList"
    >
      <AccordionItem border={0}>
        <AccordionButton px={0} _hover={{ bg: "none" }}>
          <HStack width="100%">
            <Text fontSize="sm">Artikelen</Text>
            <AccordionIcon />
          </HStack>
        </AccordionButton>
        <AccordionPanel p={0}>
          <VStack>
            {products.map(product => (
              <Flex
                key={product.id}
                data-cy="shortListProduct"
                width="100%"
                alignItems="start"
                justifyContent="space-between"
                borderBottom="1px"
                borderColor="gray.300"
                pb="0.5rem"
                sx={{
                  "&:first-of-type": {
                    borderTop: "1px",
                    borderColor: "gray.300",
                    paddingTop: "0.5rem",
                  },
                }}
              >
                <Box flexShrink={0} w="100%">
                  <LabeledPrice
                    boldPrice
                    size="xs"
                    label={`${product.name} (${product.quantity})`}
                    price={Number(product.price) * product.quantity}
                  />
                </Box>
              </Flex>
            ))}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
