import { useToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useCustomer } from "@/modules/customers";
import { clearNotifications } from "@/modules/notifications/data/NotificationsSlice";
import { selectNotifications } from "@/modules/notifications/data/SelectNotifications";

import { routerConfig } from "./modules";
import { hydrateUser } from "./modules/auth";
import { selectIsLoading } from "./modules/auth/data/selectIsLoading";
import { auth } from "./modules/auth/firebase";
import { loadBasketThunk } from "./modules/basket/data/thunks/LoadBasketThunk";
import {
  ErrorFallback,
  useAppDispatch,
  useAppSelector,
} from "./modules/common";
import { Spinner } from "./modules/common/components/Spinner";

function App() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);
  const { getCustomer } = useCustomer();
  const toast = useToast();
  const notifications = useAppSelector(selectNotifications);

  useEffect(() => {
    if (notifications.length <= 0) return;

    notifications.forEach(notification => {
      toast({
        title: notification.title,
        description: notification.description,
        status: notification.status,
        duration: 5000,
        isClosable: true,
      });
    });

    dispatch(clearNotifications());
  }, [dispatch, notifications, toast]);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async user => {
      if (user) {
        // LogRocket.identify(user?.uid, {
        //   email: user.email!,
        // });
        getCustomer();
        dispatch(loadBasketThunk());
      }
      dispatch(hydrateUser({ user: user?.toJSON() }));
    });

    return () => unsub();
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback as any}>
      <BrowserRouter>
        <Routes>
          {routerConfig.map(route => (
            <Route key={route.path} {...route} />
          ))}
        </Routes>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}

export default App;
