import { ModuleRouter, ProtectedRoute } from "../common";
import { OrderHistoryPage } from "./OrderHistoryPage";

export * from "./api";
export * from "./components";
export * from "./data";
export * from "./OrderHistoryPage";

export const historyPageConfiguration: ModuleRouter = [
  {
    path: "/bestellingen",
    element: (
      <ProtectedRoute>
        <OrderHistoryPage />
      </ProtectedRoute>
    ),
  },
];
