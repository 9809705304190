import { Container } from "@chakra-ui/react";

import { selectIsLoading } from "../auth/data/selectIsLoading";
import { useAppSelector } from "../common";
import { Spinner } from "../common/components/Spinner";
import { Banner, Footer, Heading } from ".";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../auth/data/selectUser";

type Props = {
  children: JSX.Element;
};

export function Layout({ children }: Props) {
  const isLoading = useAppSelector(selectIsLoading);
  // const user = useAppSelector(selectUser);
  // const navigate = useNavigate();

  if (isLoading) {
    return <Spinner />;
  }

  // const allowedUsers = new Set(["test@zzapps.nl", "devteam@zzapps.nl"]);

  // if (!allowedUsers.has(user?.email || "")) {
  //   navigate("/maintenance");
  // }

  return (
    <Container maxW="full" p={0} pb={8} pt={{ base: "4.2rem", md: 28 }}>
      <Banner />
      <Heading />
      {children}
      <Footer />
    </Container>
  );
}
