import { useAppDispatch, useAppSelector } from "@/modules/common";

import { clearOrder, selectOrder, setSaturdayDeliveryProduct } from "../api";
import { createOrder } from "../api/CreateOrderThunk";
import { getSaturdayDeliveryUsabilityThunk } from "../api/GetSaturdayDeliveryUsabilityThunk";

export function useOrder() {
  const dispatch = useAppDispatch();
  const { status, error } = useAppSelector(selectOrder);

  function orderComplete(): void {
    dispatch(clearOrder());
  }

  function onGetSaturdayDeliveryUsability(): void {
    dispatch(getSaturdayDeliveryUsabilityThunk());
  }

  function onSetSaturdayDeliveryUsability(
    hasSaturdayDeliveryProduct: boolean
  ): void {
    dispatch(setSaturdayDeliveryProduct(hasSaturdayDeliveryProduct));
  }

  return {
    orderComplete,
    onGetSaturdayDeliveryUsability,
    onSetSaturdayDeliveryUsability,
    status,
    error,
  };
}
