import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import debounce from "lodash/debounce";
import { useCallback, useState } from "react";
import { MdClear, MdSearch } from "react-icons/md";

import { useAppSelector } from "@/modules/common";
import { selectSearch } from "@/modules/products/api/SelectSearch";

import { OnSearchFn } from "..";

interface ISearchProps {
  onSearch: OnSearchFn;
}

export const Searchbar = ({ onSearch }: ISearchProps) => {
  const searchValue = useAppSelector(selectSearch);
  const [value, setValue] = useState<string | undefined>(searchValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(onSearch, 300), []);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setValue(value);
    debounceFn(value);
  };

  return (
    <Box
      w="full"
      pr={{ base: 0, lg: 4 }}
      m="0 !important"
      bg="bsWhite"
      minW="250px"
      zIndex={2}
    >
      <InputGroup border="1px solid" borderRadius="5" borderColor="bsGray.200">
        <InputLeftElement pointerEvents="none">
          <Icon as={MdSearch} w={4} h={4} color="gray.500" />
        </InputLeftElement>
        <Input
          data-public
          data-cy="searchbar"
          bg="bsWhite"
          border="none"
          value={value}
          placeholder="Zoek producten"
          fontSize="sm"
          size="md"
          _focusVisible={{
            outlineOffset: "-1px",
            outline: "2px solid",
            outlineColor: "bsBlack",
          }}
          onInput={handleChange}
        />
        {value && (
          <InputRightElement>
            <Icon
              as={MdClear}
              data-cy="clearSearchButton"
              w={4}
              h={4}
              cursor="pointer"
              onClick={() => {
                setValue("");
                onSearch("");
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
};
