import { createAsyncThunk } from "@reduxjs/toolkit";

import { BasketApiImpl } from "../../api/BasketApi";

export const updateSaturdayDeliveryThunk = createAsyncThunk(
  "basket/update-saturday-delivery",
  () => {
    return BasketApiImpl.getInstance().updateSaturdayDelivery();
  }
);
