import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@/modules/common";

import { BasketApiImpl, BasketProduct } from "../../api";
import { addProduct, updateProduct } from "../BasketSlice";
import { OrderItem } from "../OrderItem";

export const updateOrderItemsThunk = createAsyncThunk(
  "basket/update-order-items",
  async (orderItems: OrderItem[], api) => {
    const state = api.getState() as RootState;

    const basketProducts = state.basket.basket.products;

    const response = await BasketApiImpl.getInstance().updateOrderItems(
      {
        orderItems: orderItems.map(item => ({
          productId: item.productId,
          amount: item.amount,
        })),
      },
      api.signal
    );

    if (!("data" in response))
      throw Error("Something went wrong. Response does not contain data");

    response.data.products.forEach((product: BasketProduct) => {
      const inBasket = basketProducts.some(x => x.id === product.id);
      if (!inBasket) {
        api.dispatch(addProduct({ ...product, quantity: product.quantity }));
      } else if (inBasket) {
        const basketProd = state.basket.basket.products.find(
          prod => prod.id === product.id
        );

        if (!basketProd) {
          return;
        }

        api.dispatch(
          updateProduct({
            id: product.id,
            quantity: product.quantity + basketProd.quantity,
          })
        );
      }
    });

    const newState = api.getState() as RootState;

    return BasketApiImpl.getInstance().updateOrderItems(
      {
        orderItems: newState.basket.basket.products.map(x => ({
          productId: x.id,
          amount: x.quantity,
        })),
      },
      api.signal
    );
  }
);
