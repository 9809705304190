import { Flex } from "@chakra-ui/react";
import { useRef } from "react";

import { BasketProduct } from "@/modules/basket";
import { List, useAppDispatch, useAppSelector } from "@/modules/common";

import { selectBasketProducts } from "../data/selectors/SelectBasketProducts";
import { updateBasketProductsThunk } from "../data/thunks/UpdateBasketProductsThunk";
import { BasketItem } from "./BasketItem";

export function BasketList() {
  const products = useAppSelector(selectBasketProducts);

  const requestRef = useRef<() => void>();
  const dispatch = useAppDispatch();

  function updateBasket(product: BasketProduct, quantity: number) {
    if (requestRef.current) {
      requestRef.current();
    }

    const req = dispatch(updateBasketProductsThunk({ ...product, quantity }));

    requestRef.current = () => req.abort();
  }

  const filteredProducts = products.filter(
    prod =>
      !prod.saturdayDelivery &&
      prod.id !== "410d3ef3-a07e-4dff-b2e6-df04747b47e5"
  );

  return (
    <Flex
      flexDirection="column"
      w={{ base: "100%", md: "50%", lg: "60%" }}
      gap={3}
    >
      <List space={2}>
        {filteredProducts.map(product => (
          <List.Item key={product.id}>
            <BasketItem {...product} updateBasket={updateBasket} />
          </List.Item>
        ))}
      </List>
    </Flex>
  );
}
