// eslint-disable-next-line import/no-named-as-default
import Axios from "axios";

import { auth } from "../auth/firebase";

export const axios = Axios.create({
  baseURL: process.env.VITE_BASE_URL,
});

axios.interceptors.request.use(async (req: any): Promise<any> => {
  if (!req.headers) return req;

  req.headers["Authorization"] =
    "Bearer " + (await auth.currentUser?.getIdToken());

  return req;
});
