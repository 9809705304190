import { Container, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { FiAlertCircle } from "react-icons/fi";
import { TbCreditCardOff } from "react-icons/tb";

export function OrderCheckoutFailurePage() {
  return (
    <Container maxW="1400px" pb={8}>
      <VStack bg="white" mt={10} p={10}>
        <Flex p={5}>
          <VStack
            borderRadius="50%"
            p={3}
            bgColor="rgba(197, 48, 48, 0.1)"
            w="10rem"
            h="10rem"
            alignItems="center"
            justifyContent="center"
          >
            <TbCreditCardOff size={100} color="#ffffff" />
          </VStack>
          <Flex
            position="relative"
            mb={-10}
            ml={-10}
            bgColor="red.400"
            h="4rem"
            w="4rem"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
          >
            <FiAlertCircle size={50} color="#FFFFFF" />
          </Flex>
        </Flex>
        <Heading as="h2">Betaling is niet goed gegaan</Heading>
        <Text>
          De betaling is niet helemaal goed gegaan. Neem contact met ons op voor
          verdere stappen.
        </Text>
      </VStack>
    </Container>
  );
}
