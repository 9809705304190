import {
  FailedToGetProductProblemDetails,
  FailedToGetProductsFromBigQueryProblemDetails,
  FailedToGetProductsFromCacheProblemDetails,
} from "./ProductsApi";

export function errorMapper(
  details:
    | FailedToGetProductsFromBigQueryProblemDetails
    | FailedToGetProductsFromCacheProblemDetails
    | FailedToGetProductProblemDetails
): string {
  switch (details.type) {
    case "FailedToGetProductException":
      return "Het product kan niet worden geladen";
    case "FailedToGetProductsFromBigQueryException":
      return "De producten kunnen niet worden opgehaald";
    case "FailedToGetProductsFromCacheException":
      return "De producten kunnen niet worden opgehaald";
    default:
      return `Er ging iets fout. Error Code: ${details.type}`;
  }
}
