import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { CustomInputField } from "@/modules/common";

import { NewCustomerDto, useCustomer } from "..";

type Props = NewCustomerDto;

const schema = yup.object().shape({
  email: yup.string().email().required("Voer een e-mailadres in"),
  address: yup
    .object({
      street: yup.string().required("Voer een straatnaam en huisnummer in"),
      postalCode: yup.string().required("Voer een postcode in"),
      city: yup.string().required("Voer een plaatsnaam in"),
      country: yup.string().required("Voer een land in"),
    })
    .required(),
  // altAddress: yup.object({
  //   street: yup.string(),
  //   postalCode: yup.string(),
  //   city: yup.string(),
  //   country: yup.string(),
  // }),
});

export function CustomerForm({
  name,
  email,
  phone,
  address,
  altAddress,
  website,
}: Props) {
  const { changeCustomer, addAlternativeAddress } = useCustomer();
  const [showAltAddress, setShowAltAddress] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: name,
      email: email,
      phone: phone,
      address: address,
      altAddress: altAddress,
      website: website,
      sameAddress: "checked",
    },
  });

  const saveAccount = handleSubmit(async data => {
    const customerUpdate = {
      name: data.name,
      phone: data.phone,
      city: data.address.city,
      address: data.address.street,
      postalCode: data.address.postalCode,
      country: data.address.country,
      website: data.website,
    };

    try {
      if (altAddress !== undefined) {
        await changeCustomer(data);
        await addAlternativeAddress(data);
        return;
      }

      await changeCustomer(customerUpdate);
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <>
      <form onSubmit={saveAccount}>
        <Flex flexDir="column" w="full" gap={4} mb={4}>
          <CustomInputField label="Naam" register={register("name")} />
          <CustomInputField
            isDisabled
            isInvalid={Boolean(errors.email)}
            errorMessage={errors.email?.message}
            register={register("email")}
            label="E-mail"
          />
          <CustomInputField
            label="Telefoonnummer"
            register={register("phone")}
          />
          <CustomInputField
            label="Straat en huisnummer"
            isInvalid={Boolean(errors.address?.street)}
            errorMessage={errors.address?.street?.message}
            register={register("address.street")}
          />
          <CustomInputField
            label="Postcode"
            isInvalid={Boolean(errors.address?.postalCode)}
            errorMessage={errors.address?.postalCode?.message}
            register={register("address.postalCode")}
          />
          <CustomInputField
            label="Plaats"
            isInvalid={Boolean(errors.address?.city)}
            errorMessage={errors.address?.city?.message}
            register={register("address.city")}
          />
          <FormControl w="full" isInvalid={Boolean(errors.address?.country)}>
            <VStack alignItems="flex-start">
              <FormLabel fontSize="sm" ml={2} mb={1}>
                Land
              </FormLabel>
              <Select
                fontSize="sm"
                marginTop="0px !important"
                data-cy="country"
                placeholder="Land"
                borderRadius={5}
                _hover={{ cursor: "pointer" }}
                borderColor="#b2b2b2"
                _focusVisible={{
                  outlineOffset: "-1px",
                  outline: "2px solid",
                  outlineColor: "bsBlack",
                }}
                {...register("address.country")}
              >
                <option value="Nederland">Nederland</option>
                <option value="België">België</option>
              </Select>
              {errors.address?.country?.message && (
                <Text color="#E53E3E" fontSize="xs" mt={1} ml={2}>
                  {errors.address?.country?.message}
                </Text>
              )}
            </VStack>
          </FormControl>
          <CustomInputField label="Website" register={register("website")} />
        </Flex>

        <Flex my={8} display="none">
          <Checkbox
            defaultChecked
            onChange={() => setShowAltAddress(!showAltAddress)}
          >
            Bezorgadres is hetzelfde als het factuuradres
          </Checkbox>
        </Flex>

        {showAltAddress && (
          <>
            <Text fontSize="md" my={4}>
              Alternatief bezorgadres
            </Text>
            <Divider my={4} />
            <Flex
              flexDir={{ base: "column", lg: "row" }}
              w="full"
              gap={4}
              mb={4}
            >
              <CustomInputField
                label="Straat en huisnummer"
                isInvalid={Boolean(errors.altAddress?.street)}
                errorMessage={errors.altAddress?.street?.message}
                register={register("altAddress.street")}
              />
              <CustomInputField
                label="Postcode"
                isInvalid={Boolean(errors.altAddress?.postalCode)}
                errorMessage={errors.altAddress?.postalCode?.message}
                register={register("altAddress.postalCode")}
              />
              <CustomInputField
                label="Plaats"
                isInvalid={Boolean(errors.altAddress?.city)}
                errorMessage={errors.altAddress?.city?.message}
                register={register("altAddress.city")}
              />
            </Flex>
            <Flex flexDir="column" w="full" gap={4} mb={4}>
              <FormControl
                w="full"
                isInvalid={Boolean(errors.altAddress?.country)}
              >
                <VStack alignItems="flex-start">
                  <FormLabel fontSize="sm" ml={2} mb={1}>
                    Land
                  </FormLabel>
                  <Select
                    fontSize="sm"
                    marginTop="0px !important"
                    data-cy="altCountry"
                    placeholder="Land"
                    borderRadius={5}
                    _hover={{ cursor: "pointer" }}
                    _focusVisible={{
                      outlineOffset: "-1px",
                      outline: "2px solid",
                      outlineColor: "bsBlack",
                    }}
                    {...register("altAddress.country")}
                  >
                    <option value="NL">Nederland</option>
                    <option value="BE">België</option>
                  </Select>
                  {errors.altAddress?.country?.message && (
                    <Text color="#E53E3E" fontSize="xs" mt={1} ml={2}>
                      {errors.altAddress?.country?.message}
                    </Text>
                  )}
                </VStack>
              </FormControl>
            </Flex>
          </>
        )}

        <Flex justifyContent="flex-end" mt={5} gap={4}>
          <Button
            data-cy="cancelAccountChanges"
            bg="transparent"
            border="1px solid"
            borderColor="bsBlack"
            color="bsBlack"
            borderRadius={5}
            fontSize="sm"
            w="full"
            fontWeight="regular"
            disabled={!isDirty}
            p={4}
            _hover={{
              borderColor: "bsBlack",
            }}
            _active={{
              borderColor: "bsBlack",
              opacity: 0.8,
            }}
            _disabled={{
              opacity: 0.2,
            }}
            onClick={() => reset()}
          >
            Annuleren
          </Button>
          <Button
            data-cy="saveAccountChanges"
            bg="bsBlack"
            color="bsWhite"
            type="submit"
            borderRadius={5}
            fontSize="sm"
            fontWeight="regular"
            w="full"
            p={4}
            disabled={!isDirty}
            _hover={{
              bg: "bsBlack",
            }}
            _active={{
              bg: "bsBlack",
              opacity: 0.8,
            }}
            _disabled={{
              opacity: 0.2,
            }}
          >
            Opslaan
          </Button>
        </Flex>
      </form>
    </>
  );
}
